import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Market } from "../models/market.model";

@Injectable({ providedIn: "root" })
export class MarketService {
  private apiUrl = environment.apiUrl + "/market";

  constructor(private httpClient: HttpClient) {}

  getAll() {
    return this.httpClient.get<Market[]>(`${this.apiUrl}/getAll`);
  }
}
