import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ChangePointManagementFilter } from "../models/change-point-management-filter";
import { RealTimeManagementFilter } from "../models/real-time-management-filter";

@Injectable({
  providedIn: "root",
})
export class ExcelService {
  private urlRtm = environment.apiUrl + "/rtm";
  private urlCpm = environment.apiUrl + "/cpm";

  constructor(private http: HttpClient) {}

  public rtmData(filter: RealTimeManagementFilter): Observable<Blob> {    
    return this.http.get(`${this.urlRtm}/generate`, {
      params: new HttpParams()
        .set("marketId", filter.marketId)
        .set("segmentId", filter.segmentId)
        .set("functionId", filter.functionId)
        .set("issueTypeId", filter.issueTypeId)
        .set("detailId", filter.detailId)
        .set("status", filter.status)
        .set("periodFrom", filter.periodFrom)
        .set("periodTo", filter.periodTo),
      responseType: "blob",
    });
  }

  public rtmMasterData(): Observable<Blob> {
    return this.http.get(`${this.urlRtm}/generate-master`, {
      responseType: "blob",
    });
  }

  public cpmData(filter: ChangePointManagementFilter): Observable<Blob> {
    return this.http.get(`${this.urlCpm}/generate`, {
      params: new HttpParams()
      .set("marketId", filter.marketId)
      .set("segmentId", filter.segmentId)
      .set("functionId", filter.functionId)
      .set("issueTypeId", filter.issueTypeId)
      .set("detailId", filter.detailId)
      .set("periodFrom", filter.periodFrom)
      .set("periodTo", filter.periodTo),
      responseType: "blob",
    });
  }

  public cpmDataMaster(): Observable<Blob> {
    return this.http.get(`${this.urlCpm}/generate-master`, {
      responseType: "blob",
    });
  }
}
