// ----- MODULES ----- //
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { TegelModule } from "@scania/tegel-angular";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MsalGuard, MsalInterceptor, MsalModule } from "@azure/msal-angular";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatIconModule } from "@angular/material/icon";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { DatePipe } from "@angular/common";
import { MsalRedirectComponent } from "@azure/msal-angular";
import { MatRadioModule } from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatButtonModule } from "@angular/material/button";
import { environment } from "src/environments/environment";

// ----- COMPONENTS ----- //
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./core/components/header/header.component";
import { FooterComponent } from "./core/components/footer/footer.component";
import { SidebarComponent } from "./core/components/sidebar/sidebar.component";
import { MainContentComponent } from "./core/components/main-content/main-content.component";

import { AccessDeniedComponent } from './shared/components/layout/access-denied/access-denied.component';
import { HomeComponent } from "./shared/components/pages/home/home.component";

import { ConfirmationModalComponent } from "./shared/components/confirmation-modal/confirmation-modal.component";
import { FormModalComponent } from "./shared/components/form-modal/form-modal.component";
import { ProfileComponent } from "./shared/components/pages/profile/profile.component";
import { ToastComponent } from "./shared/components/layout/toast/toast.component";

import { RealTimeManagementComponent } from "./real-time-management/real-time-management.component";
import { RealTimeManagementFormComponent } from "./real-time-management/real-time-management-form/real-time-management-form.component";
import { RealTimeManagementSearchComponent } from './real-time-management/real-time-management-search/real-time-management-search.component';
import { RealTimeManagementSetupComponent } from './real-time-management/real-time-management-setup/real-time-management-setup.component';

import { ChangePointManagementComponent } from "./change-point-management/change-point-management.component";
import { ChangePointManagementFormComponent } from './change-point-management/change-point-management-form/change-point-management-form.component';
import { ChangePointManagementSearchComponent } from './change-point-management/change-point-management-search/change-point-management-search.component';
import { ChangePointManagementSetupComponent } from './change-point-management/change-point-management-setup/change-point-management-setup.component';

import { ExcelDownloadButtonComponent } from "./shared/components/buttons/excel-download-button";
import { TableComponent } from "./shared/components/tables/table/table.component";
import { DeletionConfirmationModalComponent } from "./shared/components/modals/deletion-confirmation-modal/deletion-confirmation-modal.component";

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;
  
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    MainContentComponent,
    FooterComponent,
    ConfirmationModalComponent,
    RealTimeManagementComponent,
    RealTimeManagementFormComponent,
    RealTimeManagementSearchComponent,
    RealTimeManagementSetupComponent,
    ChangePointManagementComponent,
    ChangePointManagementFormComponent,
    ChangePointManagementSearchComponent,
    ChangePointManagementSetupComponent,
    ProfileComponent,
    ToastComponent,
    HomeComponent,
    FormModalComponent,
    AccessDeniedComponent,
    ExcelDownloadButtonComponent,
    TableComponent,
    DeletionConfirmationModalComponent
  ],
  imports: [
    BrowserModule,
    TegelModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatRadioModule,
    MatTableModule,
    MatSelectModule,
    MatSortModule,
    MatPaginatorModule,
    FontAwesomeModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: "7059ab5c-1b6a-4a5f-9897-ac711453e7d6", // Application (client) ID from the app registration
          authority:
            "https://login.microsoftonline.com/3bc062e4-ac9d-4c17-b4dd-3aad637ff1ac", // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          redirectUri: environment.urlLogin, // This is your redirect URI
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: false,
        },
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: ["user.read"],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
          [environment.scopeUrl, ["api://7059ab5c-1b6a-4a5f-9897-ac711453e7d6/rtm-cpm"]]
        ]),
      }
    ),
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS, // Provides as HTTP Interceptor
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA], // Add the Schema
})
export class AppModule {}
