import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { TableHeader } from "src/app/shared/models/table-header.model";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() data: any[] = [];
  @Input() columns: TableHeader[] = [];

  @Output() editing: EventEmitter<any> = new EventEmitter<any>();
  @Output() excluding: EventEmitter<any> = new EventEmitter<any>();

  displayedColumns: string[] = [];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor() {}

  ngOnInit() {
    this.displayedColumns = [
      ...this.columns.map((col) => col.field),
      "actions",
    ];
    this.dataSource = new MatTableDataSource(this.data);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort!;
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort!;
  }

  edit(item: any) {
    this.editing.emit(item);
  }

  exclude(item: any) {
    this.excluding.emit(item);
  }
}
