<!-- SUCCESS -->
<div class="sdds-toast sdds-toast-success"
    *ngIf="(toast.toastState$ | async) == 'success' && (toast.showsToast$ | async)">

    <div class="sdds-toast-icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
    </div>

    <div class="sdds-toast-content">

        <div class="sdds-toast-header">
            <span class="sdds-toast-headline">{{ toast.toastMessage$ | async }}</span>
            <span class='sdds-toast-dismiss' (click)="dismiss()"></span>
        </div>

    </div>
</div>


<!-- INFORMATION -->
<div class="sdds-toast sdds-toast-info"
    *ngIf="(toast.toastState$ | async) == 'information' && (toast.showsToast$ | async)">

    <div class="sdds-toast-icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
    </div>

    <div class="sdds-toast-content">

        <div class="sdds-toast-header">
            <span class="sdds-toast-headline">{{ toast.toastMessage$ | async }}</span>
            <span class='sdds-toast-dismiss' (click)="dismiss()"></span>
        </div>

    </div>
</div>

<!-- WARNING -->
<div class="sdds-toast sdds-toast-warning"
    *ngIf="(toast.toastState$ | async) == 'warning' && (toast.showsToast$ | async)">

    <div class="sdds-toast-icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
    </div>

    <div class="sdds-toast-content">

        <div class="sdds-toast-header">
            <span class="sdds-toast-headline">{{ toast.toastMessage$ | async }}</span>
            <span class='sdds-toast-dismiss' (click)="dismiss()"></span>
        </div>

    </div>
</div>

<!-- ERROR -->
<div class="sdds-toast sdds-toast-error"
    *ngIf="(toast.toastState$ | async) == 'error' && (toast.showsToast$ | async)">

    <div class="sdds-toast-icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
    </div>

    <div class="sdds-toast-content">

        <div class="sdds-toast-header">
            <span class="sdds-toast-headline">{{ toast.toastMessage$ | async }}</span>
            <span class='sdds-toast-dismiss' (click)="dismiss()"></span>
        </div>

    </div>
</div>