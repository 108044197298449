import {
  Component,
  EventEmitter,
  Input,
  Output,
  Renderer2,
} from "@angular/core";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent {
  collapsed = true;

  @Input() mobileMenuOpen = false;
  menuItems = [
    {
      label: "Real Time Management",
      route: "real-time-management",
      subMenu: null,
    },
    {
      label: "Change Point Management",
      route: "change-point-management",
      subMenu: null,
    },
  ];
  @Output() eventFromSidebar = new EventEmitter<any>();

  constructor(private renderer: Renderer2) {}

  closeMobileMenu() {
    this.eventFromSidebar.emit({
      type: "showMobileMenu",
      value: false,
    });
  }

  closeMenu(menu: any) {
    if (!menu.subMenu) {
      this.eventFromSidebar.emit({
        type: "showMobileMenu",
        value: false,
      });
    }
  }

  closeSidebar(): void {
    this.collapsed = false;
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
  }

  toggleParentClass(event: any, className: string) {
    const hasClass = event.target.parentElement.classList.contains(className);

    if (hasClass) {
      this.renderer.removeClass(event.target.parentElement, className);
    } else {
      this.renderer.addClass(event.target.parentElement, className);
    }
  }

  toggleClass(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);

    if (hasClass) {
      this.renderer.removeClass(event.target, className);
    } else {
      this.renderer.addClass(event.target, className);
    }
  }
}
