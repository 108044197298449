<sdds-modal [size]="size" [id]="modalId" selector="{{'#selector-' + modalId}}" prevent>
    <h5 slot="sdds-modal-headline">{{title}}</h5>
    <p slot="sdds-modal-body">
        {{message}}  
    </p>
    <button slot="sdds-modal-actions" data-dismiss-modal
        class="sdds-btn sdds-btn-danger sdds-btn-md" (click)="modalService.sendConfirmation()"> 
        {{confirmationButtonMsg}}
    </button>
    <button slot="sdds-modal-actions" data-dismiss-modal
        class="sdds-btn sdds-btn-secondary sdds-btn-md">
        {{cancelationButtonMsg}}
    </button>
</sdds-modal>