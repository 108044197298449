import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Market } from '../models/market.model';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MarketSelectionService {
  private selectedMarketSubject: BehaviorSubject<Market>;
  public selectedMarket$: Observable<Market>;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    var sessionMarket = sessionStorage.getItem("selectedMarket");
    if (sessionMarket) {
      this.selectedMarketSubject = new BehaviorSubject<Market>(JSON.parse(sessionMarket));
    } else {
      this.selectedMarketSubject = new BehaviorSubject<Market>(new Market(0, 'MARKETS', ''));
    }
    this.selectedMarket$ = this.selectedMarketSubject.asObservable();
  }

  updateSelectedMarket(market: Market) {
    this.selectedMarketSubject.next(market);
    sessionStorage.setItem("selectedMarket", JSON.stringify(market));
  }

  onChangeMarket(url: string) {
    this.router.navigate([url]);
  }
}