import { Component, OnInit, ɵcoerceToBoolean } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { first } from "rxjs";
import { Combo } from "src/app/shared/models/combo.model";
import { ComboService } from "src/app/shared/services/combo.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { RealTimeManagementFilters } from "../models/real-time-management-filters.model";
import { RealTimeManagementService } from "../services/real-time-management.service";
import { ToastService, TOAST_STATE } from "../../shared/services/toast.service";
import { MarketSelectionService } from "src/app/shared/services/market-selection.service";
import { Market } from "src/app/shared/models/market.model";
import { RealTimeManagementTable } from "../models/real-time-management-table";
import { TableHeader } from "src/app/shared/models/table-header.model";
import { FilterService } from "src/app/shared/services/filter.service";
import { MatDialog } from "@angular/material/dialog";
import { DeletionConfirmationModalComponent } from "src/app/shared/components/modals/deletion-confirmation-modal/deletion-confirmation-modal.component";

@Component({
  selector: "app-real-time-management-search",
  templateUrl: "./real-time-management-search.component.html",
  styleUrls: ["./real-time-management-search.component.scss"],
})
export class RealTimeManagementSearchComponent implements OnInit {
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    private comboService: ComboService,
    private mkSelectionService: MarketSelectionService,
    public modalService: ModalService,
    public rtmService: RealTimeManagementService,
    private filterService: FilterService,
    private dialog: MatDialog
  ) {}

  public isUserMaster: boolean = true;
  public selectedMarket: Market | undefined;
  private selectedMarketOld: Market | undefined;

  public marketId: number | any;
  public showTable: boolean = false;

  segmentList: Combo[] = [];

  functionList: Combo[] = [];
  functionListAux: Combo[] = [];

  issueTypeList: Combo[] = [];

  detailsList: Combo[] = [];
  detailsListAux: Combo[] = [];

  responsibleAreaList: Combo[] = [];

  public searchResult: Array<RealTimeManagementTable> = [];

  public tableData: RealTimeManagementTable[] = [];
  public tableColumns: TableHeader[] = [
    { field: "id", header: "ID" },
    { field: "comments", header: "Description" },
    { field: "occurrenceDate", header: "Date" },
    { field: "functionArea", header: "Function" },
    { field: "stopTime", header: "Stop Time" },
    { field: "chassis", header: "Chassis" },
    { field: "issueType", header: "Issue Type" },
    { field: "responsibleArea", header: "Responsible Area" },
    { field: "sta", header: "STA" },
    { field: "status", header: "Status" },
  ];

  public filtersForm: FormGroup = this.fb.group({
    marketId: [0],
    segmentId: [0],
    functionId: [0],
    issueTypeId: [0],
    detailId: [0],
    status: ["null"],
    periodFrom: [""],
    periodTo: [""],
    respAreaId: [0],
  });

  ngOnInit(): void {
    this.mkSelectionService.selectedMarket$
      .pipe(first())
      .subscribe((market) => {
        this.selectedMarket = market;
      });
    this.mkSelectionService.selectedMarket$.subscribe((market) => {
      this.selectedMarket = market;
      if (
        this.selectedMarketOld &&
        this.selectedMarket != this.selectedMarketOld
      ) {
        this.mkSelectionService.onChangeMarket("/real-time-management/search");
      } else {
        this.selectedMarketOld = { ...this.selectedMarket };
      }
    });

    this.marketId = this.selectedMarket?.id ?? 0;

    if (this.marketId > 0) {
      this.filtersForm.patchValue({
        marketId: this.marketId,
      });

      this.loadCombos();

      var filters = this.filterService.getFiltersByPage("rtm-search");
      if (filters) {
        setTimeout(() => {
          this.setFilters(filters);
        }, 500);
      }
    }
  }

  private setFilters(filters: any): void {
    this.filtersForm.patchValue({
      marketId: filters.marketId,
      segmentId: filters.segmentId ?? 0,
      functionId: filters.functionId ?? 0,
      issueTypeId: filters.issueTypeId ?? 0,
      detailId: filters.detailId ?? 0,
      status: filters.status ?? "null",
      periodFrom: filters.periodFrom,
      periodTo: filters.periodTo,
      respAreaId: filters.respAreaId ?? 0,
    });

    this.onChangeSegment(this.filtersForm.value.segmentId);
    this.onChangeIssueType(this.filtersForm.value.issueTypeId);
    this.search();
  }

  public search() {
    if (this.selectedMarket && this.selectedMarket.id > 0) {
      const filters = this.saveFilters(this.filtersForm.value);
      this.rtmService.search(filters).subscribe({
        next: (result) => {
          this.tableData = result;
          this.showTable = true;
        },
        error: (err) => {
          this.toast.showToast(TOAST_STATE.error, err);
          this.showTable = false;
        },
      });
    } else {
      this.toast.showToast(TOAST_STATE.information, "Select a market!");
    }
  }

  private saveFilters(form: any): RealTimeManagementFilters {
    const filters: RealTimeManagementFilters = {
      marketId: this.marketId,
      segmentId: form.segmentId == 0 ? null : form.segmentId,
      functionId: form.functionId == 0 ? null : form.functionId,
      issueTypeId: form.issueTypeId == 0 ? null : form.issueTypeId,
      detailId: form.detailId == 0 ? null : form.detailId,
      status: form.status == "null" ? null : ɵcoerceToBoolean(form.status),
      periodFrom: form.periodFrom,
      periodTo: form.periodTo,
      responsibleAreaId: form.respAreaId == 0 ? null : form.respAreaId,
    };
    this.filterService.setFiltersByPage("rtm-search", filters);

    return filters;
  }

  public edit(data: any) {
    if (data.id != null) {
      this.router.navigateByUrl('/real-time-management/edit', {
        state: {
          rtmId: data.id
        }
      });
    }
  }

  public exclude(data: any): void {
    const dialogRef = this.dialog.open(DeletionConfirmationModalComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.rtmService.delete(data.id).subscribe({
          next: () => {
            this.toast.showToast(TOAST_STATE.success, "RTM deleted");
            this.tableData = this.tableData.filter(
              (item) => item.id !== data.id
            );
          },
          error: (e) => {
            this.toast.showToast(TOAST_STATE.error, e.error);
          },
        });
      }
    });
  }

  //#region Load Combos

  private loadCombo(item: string, arrayToFill: Combo[]): void {
    this.comboService.getSetupItemType(this.marketId, "RTM", item).subscribe({
      next: (result) => {
        if (result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            arrayToFill.push({
              label: result[i].label,
              id: result[i].id,
              parentId: result[i].parentId,
            });
          }
        }
      },
      error: (e) => {
        this.toast.showToast(TOAST_STATE.error, `Could't load ${item} data`);
      },
    });
  }

  private loadCombos() {
    this.loadCombo("Segment", this.segmentList);
    this.loadCombo("Function", this.functionList);
    this.loadCombo("Issue Type", this.issueTypeList);
    this.loadCombo("Detail", this.detailsList);
    this.loadCombo("Responsible Area", this.responsibleAreaList);
  }

  //#endregion

  public handleChange(value: any, atr: string) {
    this.filtersForm.patchValue({
      segmentId: atr == "segmentId" ? value : this.filtersForm.value.segmentId,
      issueTypeId:
        atr == "issueTypeId" ? value : this.filtersForm.value.issueTypeId,
      status: atr == "status" ? value : this.filtersForm.value.status,
    });

    if (atr == "segmentId") {
      this.onChangeSegment(value);
    }
    if (atr == "issueTypeId") {
      this.onChangeIssueType(value);
    }
  }

  private onChangeSegment(functionId: any) {
    if (functionId == "All") {
      this.functionListAux = [];
      this.filtersForm.patchValue({
        functionId: "All",
      });
    } else {
      this.functionListAux = this.functionList.filter(
        (d) => d.parentId == functionId
      );
    }
  }

  private onChangeIssueType(issueTypeId: any) {
    if (issueTypeId == "All") {
      this.detailsListAux = [];
      this.filtersForm.patchValue({
        detailId: "All",
      });
    } else {
      this.detailsListAux = this.detailsList.filter(
        (d) => d.parentId == issueTypeId
      );
    }
  }
}
