import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FilterService {
  constructor() {}

  private filters: any = {};
  private filtersByPage: { [key: string]: any } = {};

  setFiltrosGlobais(filtros: any) {
    this.filters = filtros;
  }

  setFiltersByPage(pagina: string, filtros: any) {
    this.filtersByPage[pagina] = filtros;
  }

  getFiltrosGlobais(): any {
    return this.filters;
  }

  getFiltersByPage(pagina: string): any {
    return this.filtersByPage[pagina];
  }

  clearFiltersByPage(pagina: string) {
    delete this.filtersByPage[pagina];
  }
}
