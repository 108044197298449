import { Component, OnInit, ɵcoerceToBoolean } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { first } from "rxjs";
import { Combo } from "src/app/shared/models/combo.model";
import { ComboService } from "src/app/shared/services/combo.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { RealTimeManagementFilters } from "../models/real-time-management-filters.model";
import { RealTimeManagementService } from "../services/real-time-management.service";
import { ToastService, TOAST_STATE } from "../../shared/services/toast.service";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { MarketSelectionService } from "src/app/shared/services/market-selection.service";
import { Market } from "src/app/shared/models/market.model";
import { RealTimeManagementTable } from "../models/real-time-management-table";

@Component({
  selector: "app-real-time-management-search",
  templateUrl: "./real-time-management-search.component.html",
  styleUrls: ["./real-time-management-search.component.scss"],
})
export class RealTimeManagementSearchComponent implements OnInit {
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    private comboService: ComboService,
    private activatedRoute: ActivatedRoute,
    private mkSelectionService: MarketSelectionService,
    public modalService: ModalService,
    public service: RealTimeManagementService
  ) {}

  public isUserMaster: boolean = true;
  public selectedMarket: Market | undefined;
  private selectedMarketOld: Market | undefined;

  public marketId: number | any;
  public showTable: boolean = false;

  segmentList: Array<Combo> = [];

  functionList: Array<Combo> = [];
  functionListAux: Array<Combo> = [];

  issueTypeList: Array<Combo> = [];

  detailsList: Array<Combo> = [];
  detailsListAux: Array<Combo> = [];

  public searchResult: Array<RealTimeManagementTable> = [];

  public filtersForm: FormGroup = this.fb.group({
    marketId: [0],
    segmentId: [0],
    functionId: [0],
    issueTypeId: [0],
    detailId: [0],
    status: ["null"],
    periodFrom: [""],
    periodTo: [""],
  });

  ngOnInit(): void {
    this.mkSelectionService.selectedMarket$
      .pipe(first())
      .subscribe((market) => {
        this.selectedMarket = market;
      });
    this.mkSelectionService.selectedMarket$.subscribe((market) => {
      this.selectedMarket = market;
      if (
        this.selectedMarketOld &&
        this.selectedMarket != this.selectedMarketOld
      ) {
        this.mkSelectionService.onChangeMarket("/real-time-management/search");
      } else {
        this.selectedMarketOld = { ...this.selectedMarket };
      }
    });

    this.marketId = this.selectedMarket?.id ?? 0;

    if (this.marketId > 0) {
      this.filtersForm.patchValue({
        marketId: this.marketId,
      });
      this.loadComboSegment();
      this.loadComboFunctionArea();
      this.loadComboMainType();
      this.loadComboDetails();

      setTimeout(() => {
        if (history.state.filters) {
          this.setFilters();
        }
      }, 500);
    }
  }

  private setFilters(): void {
    this.filtersForm.patchValue({
      marketId: this.activatedRoute.snapshot.paramMap.get("marketId"),
      segmentId: history.state.filters.segmentId,
      functionId: history.state.filters.functionId,
      issueTypeId: history.state.filters.issueTypeId,
      detailId: history.state.filters.detailId,
      status: history.state.filters.status,
      periodFrom: history.state.filters.periodFrom,
      periodTo: history.state.filters.periodTo,
    });

    this.onChangeSegment(this.filtersForm.value.segmentId);
    this.onChangeIssueType(this.filtersForm.value.issueTypeId);
    this.search();
  }

  public search() {
    if (this.selectedMarket && this.selectedMarket.id > 0) {
      const form = this.filtersForm.value;
      const filters: RealTimeManagementFilters = {
        marketId: this.selectedMarket.id,
        segmentId: form.segmentId == 0 ? null : form.segmentId,
        functionId: form.functionId == 0 ? null : form.functionId,
        issueTypeId: form.issueTypeId == 0 ? null : form.issueTypeId,
        detailId: form.detailId == 0 ? null : form.detailId,
        status: form.status == "null" ? null : ɵcoerceToBoolean(form.status),
        periodFrom: form.periodFrom,
        periodTo: form.periodTo,
      };

      this.service.search(filters).subscribe({
        next: (result) => {
          this.searchResult = result;
          this.showTable = true;
        },
        error: (err) => {
          this.toast.showToast(TOAST_STATE.error, err);
          this.showTable = false;
        },
      });
    } else {
      this.toast.showToast(TOAST_STATE.information, "Select a market!");
    }
  }

  public edit(id: number) {
    if (id !== null) {
      this.router.navigateByUrl(`/real-time-management/edit/${id}`, {
        state: {
          marketId: this.marketId,
          filters: this.filtersForm.value,
        },
      });
    }
  }

  public delete(id: number): void {
    if (id) {
      this.service.delete(id).subscribe({
        next: (result) => {
          this.toast.showToast(TOAST_STATE.success, "RTM deleted");
          this.searchResult = this.searchResult.filter(
            (item) => item.id !== id
          );
        },
        error: (e) => {
          this.toast.showToast(TOAST_STATE.error, e.error);
        },
      });
    }
  }

  //#region Load Combos

  private loadCombo(item: string, arrayToFill: Combo[]): void {
    this.comboService.getSetupItemType(this.marketId, "RTM", item).subscribe({
      next: (result) => {
        if (result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            arrayToFill.push({
              label: result[i].label,
              id: result[i].id,
              parentId: result[i].parentId,
            });
          }
        }
      },
      error: (e) => {
        this.toast.showToast(TOAST_STATE.error, `Could't load ${item} data`);
      },
    });
  }

  private loadComboSegment(): void {
    this.loadCombo("Segment", this.segmentList);
  }

  private loadComboFunctionArea(): void {
    this.loadCombo("Function", this.functionList);
  }

  private loadComboMainType(): void {
    this.loadCombo("Issue Type", this.issueTypeList);
  }

  private loadComboDetails(): void {
    this.loadCombo("Detail", this.detailsList);
  }

  //#endregion

  public handleChange(value: any, atr: string) {
    this.filtersForm.patchValue({
      segmentId: atr == "segmentId" ? value : this.filtersForm.value.segmentId,
      issueTypeId:
        atr == "issueTypeId" ? value : this.filtersForm.value.issueTypeId,
      status: atr == "status" ? value : this.filtersForm.value.status,
    });

    if (atr == "segmentId") {
      this.onChangeSegment(value);
    }
    if (atr == "issueTypeId") {
      this.onChangeIssueType(value);
    }
  }

  private onChangeSegment(functionId: any) {
    if (functionId == "All") {
      this.functionListAux = [];
      this.filtersForm.patchValue({
        functionId: "All",
      });
    } else {
      this.functionListAux = this.functionList.filter(
        (d) => d.parentId == functionId
      );
    }
  }

  private onChangeIssueType(issueTypeId: any) {
    if (issueTypeId == "All") {
      this.detailsListAux = [];
      this.filtersForm.patchValue({
        detailId: "All",
      });
    } else {
      this.detailsListAux = this.detailsList.filter(
        (d) => d.parentId == issueTypeId
      );
    }
  }
}
