<nav class="sdds-nav" [ngClass]="{'sdds-nav__avatar--opened' : isAvatarOpened}" [ngClass]="{'sdds-nav__app-launcher--opened' : isMarketOpened}">

    <div class="sdds-nav__left">
        <div class="div-mobile-menu">
            <button (click)="openMobileMenu()" class="sdds-nav__mob-menu-btn">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.97 6.998a1 1 0 0 1 1-1h22.05a1 1 0 0 1 0 2H4.97a1 1 0 0 1-1-1ZM3.97 15.982a1 1 0 0 1 1-1h22.05a1 1 0 0 1 0 2H4.97a1 1 0 0 1-1-1ZM3.97 24.966a1 1 0 0 1 1-1h22.05a1 1 0 0 1 0 2H4.97a1 1 0 0 1-1-1Z" fill="currentColor"></path>
                </svg>
            </button>
        </div>
        <div class="div-app-name">
            <a href="/" class="sdds-nav__app-name">
                RTM & CPM
            </a>
        </div>
    </div>

    <!-- RIGHT -->
    <div class="sdds-nav__right">
        <ul class="sdds-nav__toolbar-menu">
            <div class='sdds-nav__item sdds-nav__app-launcher'>
                <button class='sdds-nav__app-launcher-btn' (click)='openMarketSelector()'>
                    {{selectedMarket.name}}                
                </button>
                <ul class='sdds-nav__app-launcher-menu' *ngIf="selectMarkets">
                    <li class='sdds-nav__app-launcher-item sdds-nav__app-launcher-item--category'>
                        <p class='sdds-nav__app-launcher-category-title'>Markets</p>
                    </li>
                    <ng-container *ngFor="let market of selectMarkets">
                        <li class='sdds-nav__app-launcher-item'>
                            <a class='sdds-nav__app-launcher-item-core' (click)="onChangeMarket(market.acronym)">{{market.name}}</a>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </ul>
        <ul class='sdds-nav__toolbar-menu'>       
            <li class='sdds-nav__item sdds-nav__avatar' >
                <button class='sdds-nav__avatar-btn' (click)='openAvatar()'>
                    <img class="sdds-nav__avatar-img" src='https://www.svgrepo.com/show/170303/avatar.svg' alt='profile photo'/>
                </button>
      
                <ul class='sdds-nav__avatar-menu'>
                    <button class='sdds-nav__avatar-btn' onclick='toggleAvatarMenu(event)'>
                    </button>
                    <li class='sdds-nav__avatar-item sdds-nav__avatar-item--large'>
                        <div class='sdds-nav__avatar-info'>
                            <p class='sdds-nav__avatar-title'>{{username}}</p>
                            <p class='sdds-nav__avatar-subtitle'>{{useremail}}</p>
                            <p class='app-version'>App-version: {{appVersion}} </p>
                        </div>
                    </li>
                    <li class='sdds-nav__avatar-item'>
                        <a (click)="logout()" class='sdds-nav__avatar-item-core'>Logout</a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>

</nav>