<sdds-modal [size]="size" [id]="modalId" selector="{{'#selector-' + modalId}}" prevent>

    <h4 slot="sdds-modal-headline">{{title}}</h4>

    <div class="modal-body" slot="sdds-modal-body">
        <ng-content></ng-content>
    </div>
    
    <div slot="sdds-modal-actions">
        <button class="sdds-btn sdds-btn-sm sdds-btn-primary" (click)="save.emit()">{{action}}</button>&nbsp;
        <button class="sdds-btn sdds-btn-sm sdds-btn-secondary" data-dismiss-modal>{{cancel}}</button>
    </div>

</sdds-modal>