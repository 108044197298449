import { NgModule } from "@angular/core";
import { MsalGuard } from "@azure/msal-angular";
import { BrowserUtils } from "@azure/msal-browser";
import { RouterModule, Routes } from "@angular/router";
import { AuthorizationGuard } from "src/guards/authorization.guard";

import { HomeComponent } from "./shared/components/pages/home/home.component";
import { ProfileComponent } from "./shared/components/pages/profile/profile.component";
import { AccessDeniedComponent } from "./shared/components/layout/access-denied/access-denied.component";

import { RealTimeManagementComponent } from "./real-time-management/real-time-management.component";
import { RealTimeManagementFormComponent } from "./real-time-management/real-time-management-form/real-time-management-form.component";
import { RealTimeManagementSearchComponent } from "./real-time-management/real-time-management-search/real-time-management-search.component";
import { RealTimeManagementSetupComponent } from "./real-time-management/real-time-management-setup/real-time-management-setup.component";

import { ChangePointManagementComponent } from "./change-point-management/change-point-management.component";
import { ChangePointManagementFormComponent } from "./change-point-management/change-point-management-form/change-point-management-form.component";
import { ChangePointManagementSetupComponent } from "./change-point-management/change-point-management-setup/change-point-management-setup.component";
import { ChangePointManagementSearchComponent } from "./change-point-management/change-point-management-search/change-point-management-search.component";

const routes: Routes = [
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "access-denied",
    component: AccessDeniedComponent
  },
  // Menu Initial RTM
  { 
    path: "real-time-management", 
    component: RealTimeManagementComponent,
    canActivate: [AuthorizationGuard]
  },
  // Menu Register RTM
  {
    path: "real-time-management/create",
    component: RealTimeManagementFormComponent,
    pathMatch: "full",
    canActivate: [AuthorizationGuard],
    data: {
      allowedRoles: [
        "RTM_MASTER",
        "RTM_USER"
      ]
    }
  },
  //Menu Edit RTM
  {
    path: "real-time-management/edit",
    component: RealTimeManagementFormComponent,
    pathMatch: "full",
    canActivate: [AuthorizationGuard],
    data: {
      allowedRoles: [
        "RTM_MASTER",
        "RTM_USER"
      ]
    }
  },
  //Menu Setup RTM
  {
    path: "real-time-management/setup",
    component: RealTimeManagementSetupComponent,
    pathMatch: "full",
    canActivate: [AuthorizationGuard],
    data: {
      allowedRoles: [
        "RTM_MASTER"
      ]
    }
  },
  //Search RTM
  {
    path: "real-time-management/search",
    component: RealTimeManagementSearchComponent,
    pathMatch: "full",
    canActivate: [AuthorizationGuard],
    data: {
      allowedRoles: [
        "RTM_MASTER",
        "RTM_USER"
      ]
    }
  },
  // Menu Initial CPM
  {
    path: "change-point-management",
    component: ChangePointManagementComponent,
    canActivate: [AuthorizationGuard]
  },
  // Menu Register CPM
  {
    path: "change-point-management/create",
    component: ChangePointManagementFormComponent,
    pathMatch: "full",
    canActivate: [AuthorizationGuard],
    data: {
      allowedRoles: [
        "CPM_MASTER",
        "CPM_USER"
      ]
    }
  },
  //Search CPM
  {
    path: "change-point-management/search",
    component: ChangePointManagementSearchComponent,
    pathMatch: "full",
    canActivate: [AuthorizationGuard],
    data: {
      allowedRoles: [
        "CPM_MASTER",
        "CPM_USER"
      ]
    }
  },
  //Menu Edit CPM
  {
    path: "change-point-management/edit",
    component: ChangePointManagementFormComponent,
    pathMatch: "full",
    canActivate: [AuthorizationGuard],
    data: {
      allowedRoles: [
        "CPM_MASTER",
        "CPM_USER"
      ]
    }
  },
  // Menu Setup CPM
  {
    path: "change-point-management/setup",
    component: ChangePointManagementSetupComponent,
    pathMatch: "full",
    canActivate: [AuthorizationGuard],
    data: {
      allowedRoles: [
        "CPM_MASTER"
      ]
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? "enabledNonBlocking"
          : "disabled", // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
