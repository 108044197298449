import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  constructor(private msalService: MsalService) {}

  ValidateUser(): Observable<any> {
    var account = this.msalService.instance.getAllAccounts()[0];

    if (account == undefined || account.idTokenClaims == null) {
      return of(null);
    } else {
      var roles: string[] = [];
      var markets: string[] = [];

      account.idTokenClaims.roles?.forEach((mkt) => {
        if (mkt.includes("MARKET")) {
          markets.push(mkt);
        } else {
          roles.push(mkt);
        }
      });

      let user = {
        name: account.name,
        email: account.username,
        roles: roles,
        markets: markets
      };

      //localStorage.setItem("user", JSON.stringify(user));

      return of(user);
    }
  }

  public logout() {
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: "https://reflex.scania.com",
    });
  }
}
