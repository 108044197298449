import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Combo } from "../models/combo.model";

@Injectable({
  providedIn: "root",
})
export class ComboService {
  private apiUrl = environment.apiUrl + "/combo";

  constructor(private httpClient: HttpClient) {}

  getSetup(marketId: number) {
    return this.httpClient.get<Combo[]>(`${this.apiUrl}/getSetup/${marketId}`);
  }

  getSetupItem() {
    return this.httpClient.get<Combo[]>(`${this.apiUrl}/getSetupItem`);
  }

  getSetupIssueTypeDetail(marketId: number, project: string) {
    return this.httpClient.get<Combo[]>(
      `${this.apiUrl}/getSetupIssueType/${marketId}?project=${project}`
    );
  }

  getSetupItemType(marketId: number, project: string, itemType: string) {
    return this.httpClient.get<Combo[]>(
      `${this.apiUrl}/getSetupItemType/${marketId}?project=${project}&setupItemType=${itemType}`
    );
  }

  getQualityGateGroup() {
    return this.httpClient.get<Combo[]>(`${this.apiUrl}/getQualityGateGroup`);
  }

  getQualityGateComponent() {
    return this.httpClient.get<Combo[]>(
      `${this.apiUrl}/getQualityGateComponent`
    );
  }

  getQualityGateDetails() {
    return this.httpClient.get<Combo[]>(`${this.apiUrl}/getQualityGateDetail`);
  }

  getQualityGateLocalization() {
    return this.httpClient.get<Combo[]>(
      `${this.apiUrl}/getQualityGateLocalization`
    );
  }
}
