import { Component, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {  

  @Input() size: string = 'xs';
  @Input() modalId!: string;
  @Input() title!: string;
  @Input() message!: string;
  @Input() confirmationButtonMsg: string = 'Delete';
  @Input() cancelationButtonMsg: string = 'Cancel';

  constructor(
    public modalService: ModalService
    ) { }

}
