<tds-breadcrumbs>
  <tds-breadcrumb>
    <a href="/real-time-management">Real Time Management</a>
  </tds-breadcrumb>
  <tds-breadcrumb current>
    <a href="#">{{title}}</a>
  </tds-breadcrumb>
</tds-breadcrumbs>

<br>

<tds-accordion>

  <!-- Information -->
  <tds-accordion-item expanded *ngIf="title == 'Edit'">
    <div slot="header">
      <h4>Information</h4>
    </div>

    <div class="tds-row">
      <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-4">
        <mat-label>ID</mat-label>
        <mat-form-field>
          <input matInput [(ngModel)]="visibleId" disabled style="font-weight:bold;">
        </mat-form-field>
      </div>
      <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-8">
        <mat-label>User</mat-label>
        <mat-form-field>
          <input matInput [(ngModel)]="visibleUsername" disabled>
        </mat-form-field>
      </div>
    </div>
  </tds-accordion-item>

  <!-- Area -->
  <tds-accordion-item expanded>
    <div slot="header">
      <h4>Affected Area</h4>
    </div>

    <div [formGroup]="formArea">
      <div class="tds-row">
        <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
          <mat-label><span>*&nbsp;</span>Segment</mat-label>
          <mat-form-field>
            <mat-select placeholder="Choose..." (selectionChange)="onChangeSegment($event.value)"
              formControlName="segment">
              <mat-option *ngFor="let item of segmentList" [value]="item.id">{{item.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
          <mat-label><span>*&nbsp;</span>Function Area</mat-label>
          <mat-form-field>
            <mat-select placeholder="Choose..." formControlName="functionArea">
              <mat-option *ngFor="let item of functionListAux" [value]="item.id">{{item.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
          <mat-label>Shift</mat-label>
          <mat-form-field>
            <mat-select placeholder="-" formControlName="shift">
              <mat-option *ngFor="let shift of ddlShift" [value]="shift">{{shift}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </div>
  </tds-accordion-item>

  <!-- Item -->
  <tds-accordion-item expanded>
    <div slot="header">
      <h4>Analyzed Item</h4>
    </div>

    <div [formGroup]="formItem">

      <div class="tds-row">
        <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label><span>*&nbsp;</span>Issue Type</mat-label>
          <mat-form-field>
            <mat-select placeholder="Choose..." (selectionChange)="onChangeIssueType($event.value)"
              formControlName="issueTypeId">
              <mat-option *ngFor="let item of issueTypeList" [value]="item.id">{{item.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-12"
          *ngIf="issueTypeName == 'Deviation' || issueTypeName == 'Absenteeism'">
          <mat-label><span>*&nbsp;</span>Detail/Origin</mat-label>
          <mat-form-field>
            <mat-select placeholder="Choose..." (selectionChange)="onChangeDetail($event.value)"
              formControlName="detailId">
              <mat-option *ngFor="let i of detailsListAux" [value]="i.id">{{i.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="tds-row" *ngIf="issueTypeName == 'Safety'">
        <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label>Safety Details</mat-label>
          <mat-form-field appearance="fill" class="full-width">
            <mat-select formControlName="detailId">
              <mat-option *ngFor="let sd of detailsListAux" [value]="sd.id">{{sd.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-12" *ngIf="issueTypeName == 'Safety'">
          <mat-label>Absense</mat-label>
          <mat-form-field appearance="fill" class="full-width">
            <mat-select formControlName="absense">
              <mat-option *ngFor="let absense of ddlOption" [value]="absense">{{absense}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="tds-row">
        <div class="tds-col-lg-3 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label>Takt Option</mat-label>
          <mat-form-field>
            <mat-select formControlName="taktOptionId" placeholder="-">
              <mat-option *ngFor="let taktOpt of taktOptionList" [value]="taktOpt.id">{{taktOpt.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="tds-col-lg-3 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label>Andon/Stop Time</mat-label>
          <mat-form-field>
            <input matInput #stopTime formControlName="stopTime" maxlength="8" placeholder="HH:MM:SS">
            <mat-hint align="end">{{stopTime.value.length}} / 8</mat-hint>
          </mat-form-field>
        </div>

        <div class="tds-col-lg-3 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label><span>*&nbsp;</span>Occurrence Date</mat-label>
          <mat-form-field>
            <input matInput [matDatepicker]="picker" formControlName="occurenceDate">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="tds-col-lg-3 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label>STD Followed</mat-label>
          <mat-form-field>
            <mat-select formControlName="stdFollowed" placeholder="-">
              <mat-option *ngFor="let stdItem of ddlOption" [value]="stdItem">{{stdItem}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="tds-row">
        <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label><span>*&nbsp;</span>Chassis</mat-label>
          <mat-form-field>
            <input matInput #chassis placeholder="e.g.: 1234567" formControlName="chassis" maxlength="7">
            <mat-hint align="end">{{chassis.value.length}}/7</mat-hint>
          </mat-form-field>
        </div>

        <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label>Batch</mat-label>
          <mat-form-field>
            <input matInput #batch placeholder="e.g.: ABC0000/00" formControlName="batch" maxlength="10"
              [value]="batch.value.toUpperCase()">
            <mat-hint align="end">{{batch.value.length}}/10</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="tds-row">
        <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label><span>*&nbsp;</span>Comments</mat-label>
          <mat-form-field class="full-width">
            <textarea matInput formControlName="comments"></textarea>
          </mat-form-field>
        </div>

        <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label>Reason</mat-label>
          <mat-form-field class="full-width">
            <textarea matInput formControlName="reason"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="tds-row">
        <div class="tds-col-lg-2 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label><span>*&nbsp;</span>STA</mat-label>
          <mat-radio-group class="sdds-radio-button-group" formControlName="sta">
            <mat-radio-button [value]="true" color="primary"><label
                class="sdds-form-label">Yes</label></mat-radio-button>
            <mat-radio-button [value]="false" color="primary"><label
                class="sdds-form-label">No</label></mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="tds-col-lg-8 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label><span *ngIf="formItem.value.sta">*&nbsp;</span>STA Action</mat-label>
          <mat-form-field class="full-width">
            <textarea matInput formControlName="staAction"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="tds-row">
        <div class="tds-col-lg-2 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label><span>*&nbsp;</span>LTA</mat-label>
          <mat-radio-group class="sdds-radio-button-group" formControlName="lta">
            <mat-radio-button [value]="true" color="primary"><label
                class="sdds-form-label">Yes</label></mat-radio-button>
            <mat-radio-button [value]="false" color="primary"><label
                class="sdds-form-label">No</label></mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="tds-col-lg-8 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label><span *ngIf="formItem.value.lta">*&nbsp;</span>LTA Action</mat-label>
          <mat-form-field class="full-width">
            <textarea matInput formControlName="ltaAction"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="tds-row">

        <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label>Responsible Area</mat-label>
          <mat-form-field>
            <mat-select formControlName="responsibleAreaId" placeholder="-">
              <mat-option *ngFor="let responsibleArea of responsibleAreaList"
                [value]="responsibleArea.id">{{responsibleArea.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
          <mat-label>Responsible to Solve</mat-label>
          <mat-form-field>
            <input matInput formControlName="responsibleToSolve">
          </mat-form-field>
        </div>
      </div>

      <div class="tds-row">
        <div class="tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
            <mat-label>Choose File</mat-label>
            <mat-form-field>
                <button mat-icon-button matPrefix (click)="f_input.click()" title="Attach File">
                    <mat-icon>attach_file</mat-icon>
                </button>
                <button *ngIf="hasFile" mat-icon-button matSuffix (click)="downloadFile()" title="Download Original File">
                    <mat-icon>download</mat-icon>
                </button>
                <button *ngIf="fileName" mat-icon-button matSuffix (click)="removeFile()" title="Remove File">
                  <mat-icon>delete</mat-icon>
              </button>
                <input type="text" readonly matInput [value]="fileName" />
                <input type="file" multiple hidden #f_input (change)="onFileSelected($event)" />
            </mat-form-field>
        </div>
    </div>

      <div class="tds-row">
        <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-6 tds-col-xs-12">
          <mat-label><span>*&nbsp;</span>Status</mat-label>
          <mat-radio-group formControlName="status">
            <mat-radio-button [value]="true" color="primary"><label
                class="sdds-form-label">Opened</label></mat-radio-button>
            &nbsp;
            <mat-radio-button [value]="false" color="primary"><label
                class="sdds-form-label">Closed</label></mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-6 tds-col-xs-12">
          <mat-label><span>*&nbsp;</span>Direct Run</mat-label>
          <mat-radio-group formControlName="directRun">
            <mat-radio-button [value]="true" color="primary"><label
                class="sdds-form-label">Yes</label></mat-radio-button>
            &nbsp;
            <mat-radio-button [value]="false" color="primary"><label
                class="sdds-form-label">No</label></mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-6 tds-col-xs-12">
          <mat-label>Closed Date</mat-label>
          <mat-form-field class="full-width">
            <input matInput formControlName="closedDate"/>
          </mat-form-field>
        </div>
      </div>

    </div>
  </tds-accordion-item>

  <!-- Quality Gate -->
  <tds-accordion-item expanded *ngIf="hasQualityGate">
    <div slot="header">
      <h4>Quality Gate</h4>
    </div>

    <form [formGroup]="formQualityGate">

      <div class="tds-row">
        <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
          <mat-label>Group</mat-label>
          <mat-form-field>
            <mat-select formControlName="group" placeholder="Choose..."
              (selectionChange)="onChangeQualityGroup($event.value)">
              <mat-option *ngFor="let group of qgGroupCombo" [value]="group.id">{{group.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
          <mat-label>Component</mat-label>
          <mat-form-field>
            <input matInput [matAutocomplete]="component" formControlName="component" placeholder="Choose...">
            <mat-autocomplete #component="matAutocomplete" autoActiveFirstOption>
              <mat-option *ngFor="let comp of filteredComponents | async"
                [value]="comp.label">{{comp.label}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
          <mat-label>Detail</mat-label>
          <mat-form-field class="full-width">
            <input matInput [matAutocomplete]="detail" formControlName="detail">
            <mat-autocomplete #detail="matAutocomplete" autoActiveFirstOption>
              <mat-option *ngFor="let detail of filteredDetails | async"
                [value]="detail.label">{{detail.label}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

      </div>

      <div class="tds-row">
        <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
          <mat-label>Localization</mat-label>
          <mat-form-field>
            <mat-select formControlName="localization" placeholder="Choose...">
              <mat-option *ngFor="let localization of qgLocalizationCombo"
                [value]="localization.id">{{localization.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
          <mat-label>Defect</mat-label>
          <mat-form-field>
            <mat-select formControlName="defect" placeholder="Choose...">
              <mat-option *ngFor="let defect of qgDefectCombo" [value]="defect.id">{{defect.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
          <mat-label>Quality Gate Exist?</mat-label>
          <mat-form-field>
            <mat-select formControlName="qgExist" placeholder="Choose...">
              <mat-option *ngFor="let qgExist of ddlOption" [value]="qgExist">{{qgExist}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  </tds-accordion-item>

</tds-accordion>

<br>

<div>
  <tds-button type="button" variant="primary" size="sm" text="Save" (click)="save()"></tds-button>&nbsp;
  <tds-button type="button" variant="secondary" size="sm" text="{{btnCancel}}" (click)="cancel()"></tds-button>
</div>