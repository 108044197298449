import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  TOAST_STATE,
  ToastService,
} from "src/app/shared/services/toast.service";
import { Market } from "src/app/shared/models/market.model";
import { AuthorizationService } from "src/app/shared/services/authorization.service";
import { MarketSelectionService } from "src/app/shared/services/market-selection.service";
import { MarketService } from "src/app/shared/services/market.service";
import { first } from "rxjs";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() appVersion: string = "";
  @Output() eventFromHeader = new EventEmitter<any>();

  public isAvatarOpened: boolean = false;
  public isMarketOpened: boolean = false;

  public username: string = "";
  public useremail: string | null = "";
  public usermarkets: string[] = [];

  public selectMarkets: Market[] = [];
  public selectedMarket: Market = new Market(0, "MARKETS", "");
  
  constructor(
    private authService: AuthorizationService,
    private marketService: MarketService,
    private toast: ToastService,
    private userService: UserService,
    private marketSelectionService: MarketSelectionService
  ) {}

  ngOnInit(): void {
    
    setTimeout(() => {
      this.setUserData();
      this.loadMarkets();
    }, 10);
    
    this.marketSelectionService.selectedMarket$.pipe(first()).subscribe(market => {
      this.selectedMarket = market;
    });
    this.marketSelectionService.selectedMarket$.subscribe((market) => {
      this.selectedMarket = market;
    });
  }

  private setUserData() {
    var userJson = sessionStorage.getItem("rtm-cpm-user");
    var user = JSON.parse(userJson!);

    this.username = user.name;
    this.useremail = user.email;
    this.usermarkets = user.markets;
    this.userService.setUserInfo(user.name, user.email);
  }

  private loadMarkets() {
    this.marketService.getAll().subscribe({
      next: (value: Market[]) => {
        if (value && value.length > 0) {
          for (var i in value) {
            if (this.usermarkets.includes("MARKET_" + value[i].acronym)) {
              this.selectMarkets.push(value[i]);
            }
          }
        } else {
          this.toast.showToast(TOAST_STATE.error, "Can't load markets");
        }
      },
      error: (err) => {
        this.toast.showToast(TOAST_STATE.error, "Can't load markets");
      },
    });
  }

  public openMobileMenu(): void {
    this.eventFromHeader.emit({
      type: "showMobileMenu",
      value: true,
    });
  }

  public logout(): void {
    this.authService.logout();
  }

  public openAvatar() {
    this.isMarketOpened = false;
    this.isAvatarOpened = !this.isAvatarOpened;
  }

  public openMarketSelector() {
    this.isAvatarOpened = false;
    this.isMarketOpened = !this.isMarketOpened;
  }

  public onChangeMarket(selected: string) {
    const market = this.selectMarkets.find((c) => c.acronym === selected);
    if (market) {
      this.selectedMarket = market;
      this.marketSelectionService.updateSelectedMarket(market);
    }
    this.isMarketOpened = false;
  }
}
