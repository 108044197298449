import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class ModalService {

    private selectedModal: any;
    private callBackData: any;
    private confirmationCallBack: (data: any) => void = () => { };
    private cancelationCallBack: (data: any) => void = () => { };

    constructor(
        private dialog: MatDialog
    ) { }

    sendConfirmation(): void {
        this.confirmationCallBack(this.callBackData);
    }

    sendCancel(): void {
        this.cancelationCallBack(this.callBackData);
    }

    openConfirmation(callBackData: any, confirmationCallBack: (data: any) => void, cancelationCallBack: (data: any) => void = () => { }) {
        this.cancelationCallBack = cancelationCallBack;
        this.confirmationCallBack = confirmationCallBack;
        this.callBackData = callBackData;
        this.selectedModal = document.getElementById('deletion-modal');
        this.selectedModal.openModal();
    }

    openFormModal(callBackData: any, confirmationCallBack: (data: any) => void, cancelationCallBack: (data: any) => void = () => { }) {
        this.cancelationCallBack = cancelationCallBack;
        this.confirmationCallBack = confirmationCallBack;
        this.callBackData = callBackData;
        this.selectedModal = document.getElementById('form-modal');
        this.selectedModal.openModal();
    }
    
}