import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-change-point-management",
  templateUrl: "./change-point-management.component.html",
  styleUrls: ["./change-point-management.component.scss"],
})
export class ChangePointManagementComponent {
  constructor(private router: Router) {}

  public register() {
    this.router.navigate(["change-point-management/create"]);
  }

  public search() {
    this.router.navigate(["change-point-management/search"]);
  }

  public setup() {
    this.router.navigate(["change-point-management/setup"]);
  }
}
