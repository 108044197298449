import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { ExcelService } from "../../services/excel.service";

@Component({
  selector: "app-excel-download-button",
  template: `<tds-button
    type="button"
    variant="secondary"
    size="sm"
    (click)="downloadCsv()"
    text="{{ text }}"
  >
    <tds-icon slot="icon" size="16px" name="document_xls"></tds-icon>
  </tds-button>`,
})
export class ExcelDownloadButtonComponent {
  @Input() service: string = "";
  @Input() method: string = "";
  @Input() params: any;
  @Input() text: string = "";

  constructor(public excelService: ExcelService) {}

  downloadCsv() {
    switch (this.service) {
      case "RTM":
        this.downloadRtm();
        break;
      case "CPM":
        this.downloadCpm();
        break;
      default:
        break;
    }
  }

  private downloadRtm() {
    if (this.method == "generate") {
      this.excelService.rtmData(this.params).subscribe({
        next: (response) => {
          const filename = "rtm_data.csv";
          this.saveFile(response as Blob, filename);
        },
      });
    } else {
      this.excelService.rtmMasterData().subscribe({
        next: (response) => {
          const filename = "rtm_master_data.csv";
          this.saveFile(response as Blob, filename);
        },
      });
    }
  }

  private downloadCpm() {
    if (this.method == "generate") {
      this.excelService.cpmData(this.params).subscribe({
        next: (response) => {
          const filename = "cpm_data.csv";
          this.saveFile(response as Blob, filename);
        },
      });
    } else {
      this.excelService.cpmDataMaster().subscribe({
        next: (response) => {
          const filename = "cpm_master_data.csv";
          this.saveFile(response as Blob, filename);
        },
      });
    }
  }

  public saveFile(blob: Blob, filename: string): void {
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(blob); // Cria uma URL temporária para o arquivo

    link.href = url;
    link.download = filename;
    link.click(); // Dispara o download

    window.URL.revokeObjectURL(url);
  }
}
