<footer class="sdds-footer">      
    <div class="sdds-footer-main">
      <ul class="sdds-footer-main-links">  
        <li><a href="https://www.scania.com/group/en/home/admin/misc/privacy-statement/cookies.html" target="_blank" rel="nofollow noreferrer">Legal link</a></li>          
      </ul>      
      <div class="sdds-footer-main-brand">
        <p>
            © Copyright Scania 2023 All rights reserved.
        </p>
      </div>
    </div>
  </footer>
