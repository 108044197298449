import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-deletion-confirmation-modal",
  templateUrl: "./deletion-confirmation-modal.component.html",
  styleUrls: ["./deletion-confirmation-modal.component.scss"],
})
export class DeletionConfirmationModalComponent {
  constructor(
    public dialogRef: MatDialogRef<DeletionConfirmationModalComponent>
  ) {}

  confirm() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
