<table mat-table [dataSource]="dataSource" matSort>

  <ng-container matColumnDef="{{column.field}}" *ngFor="let column of columns">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header }}</th>
    <td mat-cell *matCellDef="let row">
      {{ row[column.field] }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let row">
      <div class="td-icon">
        <tds-icon name="edit" size="24px" (click)="edit(row)" title="Edit"></tds-icon>&nbsp;
        <tds-icon name="trash" size="20px" (click)="exclude(row)" title="Delete"></tds-icon>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
