import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-real-time-management",
  templateUrl: "./real-time-management.component.html",
  styleUrls: ["./real-time-management.component.scss"],
})
export class RealTimeManagementComponent {
  constructor(private router: Router) {}

  register() {
    this.router.navigate(["real-time-management/create"]);
  }

  search() {
    this.router.navigate(["real-time-management/search"]);
  }

  setup() {
    this.router.navigate(["real-time-management/setup"]);
  }
}
