import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { RtmViewModel } from "../models/rtm";
import { RealTimeManagement } from "../models/real-time-management";
import { RealTimeManagementFilters } from "../models/real-time-management-filters.model";
import { RealTimeManagementTable } from "../models/real-time-management-table";

@Injectable({
  providedIn: "root",
})
export class RealTimeManagementService {
  private url: string = environment.apiUrl + "/rtm";

  constructor(public http: HttpClient) {}

  public getById(id: number) {
    return this.http.get<RealTimeManagement>(`${this.url}/${id}`);
  }

  public getAllByMarket(marketId: number) {
    return this.http.get<RtmViewModel[]>(
      `${this.url}/getAll/marketid=${marketId}`
    );
  }

  public search(filters: RealTimeManagementFilters) {
    return this.http.post<RealTimeManagementTable[]>(
      `${this.url}/search`,
      filters
    );
  }

  public save(data: FormData) {
    return this.http.post(`${this.url}/save`, data);
  }

  public delete(id: number) {
    return this.http.delete(`${this.url}/delete?id=${id}`);
  }
}
