import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { TOAST_STATE, ToastService } from "./toast.service";

@Injectable({
  providedIn: "root",
})
export class FileService {
  constructor(private http: HttpClient, private toast: ToastService) {}

  private url = environment.apiUrl + "/file";

  public download(bucketKey: string, fileName: string, fileType: string) {
    this.getFile(bucketKey).subscribe({
      next: (data) => {
        const blob = this.convertBase64ToBlob(data.toString(), fileType);
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);

        link.href = url;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(url);
      },
      error: () => {
        this.toast.showToast(TOAST_STATE.error, "Error to download file");
      },
    });
  }

  private getFile(bucketKey: string) {
    return this.http.get(`${this.url}?bucketKey=${bucketKey}`);
  }

  private convertBase64ToBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String); // Decodifica a string Base64
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i); // Converte os caracteres para números
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType }); // Cria um Blob
  }
}
