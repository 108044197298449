/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  toggle: boolean[] = [false, false, false, false];

  constructor() { }

  ngOnInit(): void {
  }
  
  
  toggleFooterColumn(index: any) {
    this.toggle[index] = !this.toggle[index];
  }

  removeFocus($event:any) {
    $event.srcElement.blur();
  }

}
