import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ChangePointManagement } from "../models/change-point-management";
import { CpmSearchFilters } from "../models/cpm-search-filters";
import { Observable } from "rxjs";
import { CpmSearchResult } from "../models/cpm-search-result";

@Injectable({
  providedIn: "root",
})
export class ChangePointManagementService {
  private apiUrl = environment.apiUrl + "/cpm";

  constructor(private httpClient: HttpClient) {}

  public getById(id: number) {
    return this.httpClient.get<ChangePointManagement>(`${this.apiUrl}/${id}`);
  }

  public getAllByMarket(marketId: number) {
    return this.httpClient.get<ChangePointManagement[]>(
      `${this.apiUrl}/getAll/${marketId}`
    );
  }

  public getFiltered(filters: CpmSearchFilters) {
    const apiParameters = `marketId=${filters.marketId}&segmentId=${filters.segmentId}&functionId=${filters.functionId}&issueTypeId=${filters.issueTypeId}&detailId=${filters.detailId}&periodFrom=${filters.periodFrom}&periodTo=${filters.periodTo}`;

    return this.httpClient.get<CpmSearchResult[]>(
      `${this.apiUrl}/search?${apiParameters}`
    );
  }

  public save(data: ChangePointManagement) {
    return this.httpClient.post(`${this.apiUrl}/save`, data);
  }

  public delete(id: number) {
    return this.httpClient.delete(`${this.apiUrl}/${id}`);
  }
}
