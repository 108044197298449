<tds-breadcrumbs>
    <tds-breadcrumb>
        <a href="/change-point-management">Change Point Management</a>
    </tds-breadcrumb>
    <tds-breadcrumb current>
        <a href="#">{{title}}</a>
    </tds-breadcrumb>
</tds-breadcrumbs>

<br>

<tds-accordion>

    <!-- Information -->
    <tds-accordion-item expanded *ngIf="title == 'Edit'">
        <div slot="header">
            <h4>Information</h4>
        </div>

        <div class="tds-row">
            <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-4">
                <mat-label>ID</mat-label>
                <mat-form-field>
                    <input matInput [(ngModel)]="visibleId" disabled style="font-weight:bold;">
                </mat-form-field>
            </div>
            <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-8">
                <mat-label>User</mat-label>
                <mat-form-field>
                    <input matInput [(ngModel)]="visibleUsername" disabled>
                </mat-form-field>
            </div>
        </div>
    </tds-accordion-item>

    <!-- Area -->
    <tds-accordion-item expanded>
        <div slot="header">
            <h4>Affected Area</h4>
        </div>

        <div [formGroup]="formArea">
            <div class="tds-row">
                <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
                    <mat-label><span>*&nbsp;</span>Segment</mat-label>
                    <mat-form-field>
                        <mat-select placeholder="Choose..." (selectionChange)="changeSegment($event.value)"
                            formControlName="segment">
                            <mat-option *ngFor="let item of segmentList" [value]="item.id">{{item.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
                    <mat-label><span>*&nbsp;</span>Function Area</mat-label>
                    <mat-form-field>
                        <mat-select placeholder="Choose..." formControlName="functionArea">
                            <mat-option *ngFor="let item of functionListAux"
                                [value]="item.id">{{item.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
                    <mat-label>Shift</mat-label>
                    <mat-form-field>
                        <mat-select placeholder="-" formControlName="shift">
                            <mat-option *ngFor="let shift of ddlShift" [value]="shift">{{shift}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
        </div>
    </tds-accordion-item>

    <!-- Item -->
    <tds-accordion-item expanded>
        <div slot="header">
            <h4>Analyzed Item</h4>
        </div>

        <div [formGroup]="formItem">

            <div class="tds-row">
                <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
                    <mat-label><span>*&nbsp;</span>Issue Type</mat-label>
                    <mat-form-field>
                        <mat-select placeholder="Choose..." (selectionChange)="onChangeIssueType($event.value)"
                            formControlName="issueTypeId">
                            <mat-option *ngFor="let item of issueTypeList" [value]="item.id">{{item.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
                    <mat-label><span>*&nbsp;</span>Detail</mat-label>
                    <mat-form-field>
                        <mat-select placeholder="Choose..." formControlName="detailId">
                            <mat-option *ngFor="let i of detailsListAux" [value]="i.id">{{i.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="tds-row">

                <div class="tds-col-lg-4 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
                    <mat-label><span>*&nbsp;</span>Occurrence Date</mat-label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" formControlName="occurenceDate">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="tds-col-lg-2 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
                    <mat-label>Duration</mat-label>
                    <mat-form-field>
                        <input matInput formControlName="duration" type="number">
                    </mat-form-field>
                </div>
            </div>

            <div class="tds-row">
                <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
                    <mat-label><span>*&nbsp;</span>Chassis</mat-label>
                    <mat-form-field>
                        <input matInput #chassis placeholder="e.g.: 1234567" formControlName="chassis" maxlength="7">
                        <mat-hint align="end">{{chassis.value.length}}/7</mat-hint>
                    </mat-form-field>
                </div>

                <div class="tds-col-lg-6 tds-col-md-6 tds-col-sm-6 tds-col-xs-12">
                    <mat-label>Batch</mat-label>
                    <mat-form-field>
                        <input matInput #batch placeholder="e.g.: ABC0000/00" formControlName="batch" maxlength="10"
                            [value]="batch.value.toUpperCase()">
                        <mat-hint align="end">{{batch.value.length}}/10</mat-hint>
                    </mat-form-field>
                </div>
            </div>

            <div class="tds-row">
                <div class="tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                    <mat-label><span>*&nbsp;</span>Comments</mat-label>
                    <mat-form-field>
                        <textarea matInput formControlName="comments"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="tds-row">
                <div class="tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                    <mat-label>Choose File</mat-label>
                    <mat-form-field>
                        <button mat-icon-button matPrefix (click)="f_input.click()" title="Attach File">
                            <mat-icon>attach_file</mat-icon>
                        </button>
                        <button mat-icon-button matSuffix (click)="downloadFile()" title="Download File">
                            <mat-icon>download</mat-icon>
                        </button>
                        <input type="text" readonly matInput [value]="fileName" />
                        <input type="file" multiple hidden #f_input (change)="onFileSelected($event)" />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </tds-accordion-item>

</tds-accordion>

<br>

<div>
    <tds-button type="button" variant="primary" size="sm" text="Save" (click)="save()"></tds-button>&nbsp;
    <tds-button type="button" variant="secondary" size="sm" text="{{btnCancel}}" (click)="cancel()"></tds-button>
</div>