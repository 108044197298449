import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate {

  constructor(
    private router: Router,
    private authorizationService: MsalService,
    private authorization: AuthorizationService
  ) { }

  canActivate(

    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     
    this.authorization.ValidateUser().subscribe((account: any) => {
      
      if (account == null) {     
        this.authorizationService.instance.loginRedirect({
          scopes: ["User.Read"]
        });
        return false;
      }
      const allowedRoles = route.data['allowedRoles'];
      
      if (!allowedRoles)
        return true;
      
      if (!account.roles || account.roles.length == 0) {
        this.router.navigate(['/access-denied']);
        return false;
      }

      const rolesFound = account.roles.filter((x: any) => allowedRoles.filter((y: any) => y == x).length > 0);
      
      if (rolesFound.length == 0) {
        this.router.navigate(['/access-denied']);
        return false;
      }

      return true;
    });

    return true;
  }

}