<!-- <div class="background-home" style="background-image: url(../../assets/images/sdds-landing-page-bg.png);">
    <div>
      <h1>RTM & CPM</h1>
    </div>
  </div>
   -->

<div *ngIf="!loginDisplay">
  <p>Please sign-in to see your profile information.</p>
</div>

<div *ngIf="loginDisplay">
  <p>Login successful!</p>
  <p>Request your profile information by clicking Profile above.</p>
</div>