import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import {
  combineLatest,
  first,
  map,
  Observable,
  of,
  startWith,
  Subject,
} from "rxjs";
import { Combo } from "src/app/shared/models/combo.model";
import { ComboService } from "src/app/shared/services/combo.service";
import { RtmViewModel } from "../models/rtm";
import { ToastService, TOAST_STATE } from "../../shared/services/toast.service";
import { RealTimeManagement } from "../models/real-time-management";
import { RealTimeManagementService } from "../services/real-time-management.service";
import { MarketSelectionService } from "src/app/shared/services/market-selection.service";
import { Market } from "src/app/shared/models/market.model";
import { UserService } from "src/app/shared/services/user.service";
import { DatePipe } from "@angular/common";
import { FileService } from "src/app/shared/services/file.service";

@Component({
  selector: "app-real-time-management-form",
  templateUrl: "./real-time-management-form.component.html",
  styleUrls: ["./real-time-management-form.component.scss"],
})
export class RealTimeManagementFormComponent implements OnInit {
  public selectedMarket: Market | undefined;
  public selectedMarketOld: Market | undefined;

  public title: string = "";
  public btnCancel: string = "";

  private marketId: number | any;

  private id: number = 0;
  public visibleId: string = "";

  private username: string = "";
  public visibleUsername: string = "";
  public segment: string = "";

  public rtmEntity = {} as RealTimeManagement;

  public hasQualityGate: boolean = false;
  
  public file: File | null = null;
  public hasFile: boolean = false;
  public fileName: string = "";

  // Combos
  public segmentList: Combo[] = [];
  public functionList: Combo[] = [];
  public issueTypeList: Combo[] = [];
  public detailsList: Combo[] = [];
  public detailsListAux: Combo[] = [];
  public functionListAux: Combo[] = [];
  public detailsQualityList: Combo[] = [];
  public taktOptionList: Combo[] = [];
  public responsibleAreaList: Combo[] = [];

  public qgGroupCombo: Combo[] = [];
  public qgDetailCombo: Combo[] = [];
  public qgLocalizationCombo: Combo[] = [];
  public qgDefectCombo: Combo[] = []; // defect is get from Setup
  //Component needs to verify parentId of Group
  public qgComponentList: Combo[] = [];
  public qgComponentCombo: Combo[] = [];

  private selectedComponentId: number | null = null;
  private selectedDetailId: number | null = null;
  filteredComponents: Observable<Combo[]> = new Observable<Combo[]>();
  filteredDetails: Observable<Combo[]> = new Observable<Combo[]>();

  setupItens: Array<any> = [];
  issueTypeArray: Array<any> = [];
  itemTypeArray: Array<any> = [];

  issueTypeId: number | null = null;
  issueTypeName: string = "";

  functionId: number | null = null;
  functionName: string = "";

  detailId: number | null = null;
  detailName: string = "";

  setupTypeId: number = 0;
  setupTypeName: string = "";

  ddlOption = ["Yes", "No"];
  ddlShift = ["1st shift", "2nd shift"];

  //#region Forms

  public formArea: FormGroup = this.fb.group({
    segment: ["", Validators.required],
    functionArea: ["", Validators.required],
    shift: [null],
  });

  public formItem: FormGroup = this.fb.group({
    issueTypeId: ["", Validators.required],
    detailId: [null],
    absense: [""],
    taktOptionId: [null],
    occurenceDate: ["", Validators.required],
    stdFollowed: [""],
    stopTime: [""],
    chassis: [
      "",
      [Validators.required, Validators.minLength(7), Validators.maxLength(7)],
    ],
    batch: [""],
    comments: ["", [Validators.required, Validators.minLength(2)]],
    reason: [""],
    sta: [null],
    staAction: [""],
    lta: [null],
    ltaAction: [""],
    responsibleAreaId: [null],
    responsibleToSolve: [""],
    status: [null, Validators.required],
    directRun: [null, Validators.required],
    closedDate: [{ value: "", disabled: true }],
  });

  public formQualityGate: FormGroup = this.fb.group({
    id: [null],
    group: [null],
    component: [null],
    detail: [null],
    localization: [null],
    defect: [null],
    qgExist: [null],
  });

  //#endregion

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    private comboService: ComboService,
    private activatedRoute: ActivatedRoute,
    private service: RealTimeManagementService,
    private userService: UserService,
    private mkSelectionService: MarketSelectionService,
    private fileService: FileService
  ) {}

  filterComponent(value: string): Combo[] {
    const val = value.toLowerCase();
    return this.qgComponentCombo.filter((option) =>
      option.label.toLowerCase().includes(val)
    );
  }

  filterDetail(value: string): Combo[] {
    const filterValue = value.toLowerCase();
    return this.qgDetailCombo.filter((option) =>
      option.label.toLowerCase().includes(filterValue)
    );
  }

  private originalFile = {
    fileName: "",
    fileType: "",
    fileSize: 0,
    fileContent: "",
  };

  ngOnInit(): void {
    // AutoComplete - Component
    this.filteredComponents = this.formQualityGate
      .get("component")!
      .valueChanges.pipe(
        startWith(""),
        map((value) => this.filterComponent(value || ""))
      );
    this.formQualityGate.get("component")!.valueChanges.subscribe((value) => {
      this.selectedComponentId =
        this.qgComponentCombo.find((option) => option.label === value)?.id ??
        null;
    });

    // AutoComplete - Detail
    this.filteredDetails = this.formQualityGate
      .get("detail")!
      .valueChanges.pipe(
        startWith(""),
        map((value) => this.filterDetail(value || ""))
      );
    this.formQualityGate.get("detail")!.valueChanges.subscribe((value) => {
      this.selectedDetailId =
        this.qgDetailCombo.find((option) => option.label === value)?.id ?? null;
    });

    this.mkSelectionService.selectedMarket$
      .pipe(first())
      .subscribe((market) => {
        this.selectedMarket = market;
      });
    this.mkSelectionService.selectedMarket$.subscribe((market) => {
      this.selectedMarket = market;
      if (
        this.selectedMarketOld &&
        this.selectedMarket != this.selectedMarketOld
      ) {
        this.marketChange();
      } else {
        this.selectedMarketOld = { ...this.selectedMarket };
      }
    });

    this.marketId = this.selectedMarket?.id ?? 0;
    this.pageSettings();

    this.formItem.get("issueTypeId")?.valueChanges.subscribe((val) => {
      const item = this.issueTypeList.find((x) => x.id == val);

      if (item && (item.label == "Absenteeism" || item.label == "Deviation")) {
        this.formItem.controls["detailId"].setValidators([Validators.required]);
      } else {
        this.formItem.controls["detailId"].clearValidators();
      }
      this.formItem.controls["detailId"].updateValueAndValidity();
    });

    this.formItem.get("sta")?.valueChanges.subscribe((val) => {
      if (val) {
        this.formItem.controls["staAction"].setValidators([
          Validators.required,
        ]);
      } else {
        this.formItem.controls["staAction"].clearValidators();
      }
      this.formItem.controls["staAction"].updateValueAndValidity();
    });

    if (this.marketId > 0) {
      this.init();
    } else {
      this.toast.showToast(TOAST_STATE.information, "Select a Market");
    }
  }

  private pageSettings() {
    const path = this.activatedRoute.routeConfig?.path;
    var isEdit = path?.includes("edit");
    this.title = isEdit ? "Edit" : "New";
    this.btnCancel = isEdit ? "Back" : "Cancel";

    this.userService.userName$.subscribe((userName) => {
      this.username = userName;
      this.visibleUsername = this.username;
    });

    if (isEdit && history.state.rtmId) {
      this.id = history.state.rtmId;
      this.visibleId = history.state.rtmId;
    }
  }

  private async init() {
    await this.loadCombos();
    await this.loadCombosQualityGate();

    if (this.id) {
      this.loadRtm(this.id);
    }
  }

  private marketChange() {
    if (this.title == "New") {
      window.location.reload();
    } else {
      this.router.navigateByUrl("/real-time-management/search");
    }
  }

  private loadRtm(id: number) {
    this.service.getById(id).subscribe({
      next: (data: RealTimeManagement) => {
        if (data) {
          this.rtmEntity = data;
          this.initializeRtmForm(data);
          this.formItem.get("shift")?.disable();
        } else {
          this.toast.showToast(TOAST_STATE.error, "Can't load data.");
        }
      },
    });
  }

  private initializeRtmForm(data: RealTimeManagement) {
    this.username = data.username;
    // filter combo Function
    this.functionListAux = this.functionList.filter(
      (d) => d.parentId === data.segmentId
    );

    this.formArea.patchValue({
      segment: data.segmentId,
      functionArea: data.functionId,
      shift: data.shift,
    });

    this.issueTypeName =
      this.issueTypeList.find((i) => i.id == data.issueTypeId)?.label ?? "";

    this.detailName =
      this.detailsList.find((i) => i.id == data.detailId)?.label ?? "";
    this.hasQualityGate = this.detailName == "Quality Gate" ? true : false;

    // filter combo Details
    this.detailsListAux = this.detailsList.filter(
      (d) => d.parentId === data.issueTypeId
    );

    this.formItem.patchValue({
      issueTypeId: data.issueTypeId,
      detailId: data.detailId,
      absense: data.absense,
      taktOptionId: data.taktOptionId,
      occurenceDate: data.occurrenceDate,
      stdFollowed: data.stdFollowed,
      stopTime: data.stopTime,
      chassis: data.chassis,
      batch: data.batch,
      comments: data.comments,
      reason: data.reason,
      sta: data.sta,
      staAction: data.staAction,
      lta: data.lta,
      ltaAction: data.ltaAction,
      responsibleAreaId: data.responsibleAreaId,
      responsibleToSolve: data.responsibleToSolve,
      status: data.status,
      directRun: data.directRun,
      closedDate: data.closedDate,
    });

    if (data.fileBucketKey) {
      this.hasFile = true;
      this.fileName = data.fileName;
    }

    if (data.qualityGateId) {
      this.qgComponentCombo = this.qgComponentList.filter(
        (d) => d.parentId === data.qgGroupId
      );
      const component = this.qgComponentCombo.find(
        (option) => option.id === data.qgComponentId
      );
      this.selectedComponentId = component!.id;

      const detail = this.qgDetailCombo.find(
        (option) => option.id === data.qgDetailId
      );
      this.selectedDetailId = detail!.id;

      this.formQualityGate.patchValue({
        id: data.qualityGateId,
        group: data.qgGroupId,
        component: component!.label,
        detail: detail!.label,
        localization: data.qgLocalizationId,
        defect: data.qgDefectId,
        qgExist: data.qualityGateExist,
      });
    }
  }

  //#region Load Combos

  private loadCombos(): Promise<any> {
    const mkId = this.selectedMarket!.id;
    const setups = [
      "Segment",
      "Function",
      "Issue Type",
      "Detail",
      "Takt Option",
      "Responsible Area",
    ];
    const segments = this.comboService.getSetupItemType(mkId, "RTM", setups[0]);
    const functions = this.comboService.getSetupItemType(
      mkId,
      "RTM",
      setups[1]
    );
    const issueTypes = this.comboService.getSetupItemType(
      mkId,
      "RTM",
      setups[2]
    );
    const details = this.comboService.getSetupItemType(mkId, "RTM", setups[3]);
    const taktOptions = this.comboService.getSetupItemType(
      mkId,
      "RTM",
      setups[4]
    );
    const areas = this.comboService.getSetupItemType(mkId, "RTM", setups[5]);

    return new Promise<any>((resolve, reject) => {
      combineLatest([
        segments,
        functions,
        issueTypes,
        details,
        taktOptions,
        areas,
      ]).subscribe(
        ([segments, functions, issueTypes, details, taktOptions, areas]) => {
          this.segmentList = segments;
          this.functionList = functions;
          this.issueTypeList = issueTypes;
          this.detailsList = details;
          this.taktOptionList = taktOptions;
          this.responsibleAreaList = areas;
          resolve(true);
        }
      );
    });
  }

  private async loadCombosQualityGate(): Promise<any> {
    const groups = this.comboService.getQualityGateGroup();
    const components = this.comboService.getQualityGateComponent();
    const details = this.comboService.getQualityGateDetails();
    const localizations = this.comboService.getQualityGateLocalization();
    const defects = this.comboService.getSetupItemType(
      this.marketId,
      "RTM",
      "Defect"
    );

    return new Promise<any>((resolve, reject) => {
      combineLatest([
        groups,
        components,
        details,
        localizations,
        defects,
      ]).subscribe(([groups, components, details, localizations, defects]) => {
        this.qgGroupCombo = groups;
        this.qgComponentList = components;
        this.qgDetailCombo = details;
        this.qgLocalizationCombo = localizations;
        this.qgDefectCombo = defects;
        resolve(true);
      });
    });
  }

  //#endregion

  //#region Combo Change

  public onChangeSegment(segmentId: any): void {
    const functionItem = this.functionList.find(
      (x) => x.parentId === segmentId
    );

    this.formArea.patchValue({
      functionArea: "",
    });

    if (functionItem) {
      this.functionListAux = this.functionList.filter(
        (d) => d.parentId === segmentId
      );
    }
  }

  public onChangeIssueType(issueTypeId: any): void {
    const item = this.issueTypeList.find((x) => x.id == issueTypeId);

    if (item) {
      this.issueTypeId = item.id;
      this.issueTypeName = item.label;
    }

    this.detailsListAux = this.detailsList.filter(
      (d) => d.parentId === issueTypeId
    );
  }

  public onChangeDetail(id: any) {
    const item = this.detailsListAux.find((x) => x.id === id);

    if (item) {
      this.detailName = item.label;
      this.hasQualityGate = item.label == "Quality Gate" ? true : false;
    }
  }

  public onChangeQualityGroup(groupId: any) {
    if (groupId) {
      this.qgComponentCombo = this.qgComponentList.filter(
        (d) => d.parentId === groupId
      );
      this.formQualityGate.patchValue({
        component: "",
      });
    }
  }

  //#endregion

  public save() {
    if (this.formArea.valid && this.formItem.valid) {
      if (
        this.hasQualityGate &&
        this.validateQgForm(this.formQualityGate.value)
      ) {
        return;
      }

      const formData = new FormData();

      if (this.id == 0) {
        formData.append("username", this.username);
      } else {
        formData.append("id", this.id.toString());
      }

      formData.append("marketId", this.marketId);
      formData.append("segmentId", this.formArea.value.segment);
      formData.append("functionId", this.formArea.value.functionArea);
      formData.append("shift", this.formArea.value.shift);
      formData.append("issueTypeId", this.formItem.value.issueTypeId);
      formData.append("detailId", this.formItem.value.detailId);
      formData.append("taktOptionId", this.formItem.value.taktOptionId ?? "0");
      formData.append("stopTime", this.formItem.value.stopTime ?? "");

      const datepipe: DatePipe = new DatePipe("en-US");
      let formattedDate = datepipe.transform(
        this.formItem.value.occurenceDate,
        "YYYY-MM-ddTHH:mm:ss"
      );

      formData.append("occurrenceDate", formattedDate!);
      formData.append("stdFollowed", this.formItem.value.stdFollowed);
      formData.append("chassis", this.formItem.value.chassis);
      formData.append("batch", this.formItem.value.batch ?? "");
      formData.append("comments", this.formItem.value.comments);
      formData.append("reason", this.formItem.value.reason ?? "");
      formData.append("sta", this.formItem.value.sta);
      formData.append("staAction", this.formItem.value.staAction ?? "");
      formData.append("lta", this.formItem.value.lta);
      formData.append("ltaAction", this.formItem.value.ltaAction ?? "");
      formData.append(
        "responsibleAreaId",
        this.formItem.value.responsibleAreaId ?? "0"
      );
      formData.append(
        "responsibleToSolve",
        this.formItem.value.responsibleToSolve ?? ""
      );
      formData.append("status", this.formItem.value.status);
      formData.append("directRun", this.formItem.value.directRun);

      formData.append("hasQualityGate", this.hasQualityGate.toString());
      if (this.hasQualityGate) {
        formData.append("qualityGateId", this.formQualityGate.value.id);
        formData.append("qgGroupId", this.formQualityGate.value.group);
        formData.append("qgComponentId", this.selectedComponentId?.toString()!);
        formData.append("qgDetailId", this.selectedDetailId?.toString()!);
        formData.append(
          "qgLocalizationId",
          this.formQualityGate.value.localization
        );
        formData.append("qgDefectId", this.formQualityGate.value.defect);
        formData.append("qualityGateExist", this.formQualityGate.value.qgExist);
      }

      if (this.file) {
        formData.append("File", this.file, this.file.name);
      }

      if (this.originalFile.fileName) {
        formData.append("fileName", this.originalFile.fileName ?? "");
      }

      this.service.save(formData).subscribe({
        next: () => {
          this.toast.showToast(TOAST_STATE.success, "RTM Saved.");
          this.cancel();
        },
        error: (err) => {
          this.toast.showToast(TOAST_STATE.error, err.error);
        },
      });
    } else {
      this.toast.showToast(
        TOAST_STATE.warning,
        "Fill all required fields before saving"
      );
    }
  }

  public cancel() {
    if (this.btnCancel == "Back") {
      this.router.navigateByUrl("/real-time-management/search", {
        state: {
          filters: history.state.filters,
        },
      });
    } else {
      this.router.navigate(["/real-time-management"]);
    }
  }

  private validateQgForm(qgForm: any): boolean {
    if (!qgForm.qgGroupId) {
      this.toast.showToast(TOAST_STATE.warning, "Select a QG Group");
      return false;
    }

    if (!qgForm.qgComponentId) {
      this.toast.showToast(TOAST_STATE.warning, "Select a QG Component");
      return false;
    }
    if (!qgForm.qgDetailId) {
      this.toast.showToast(TOAST_STATE.warning, "Select a QG Detail");
      return false;
    }
    if (!qgForm.qgLocalizationId) {
      this.toast.showToast(TOAST_STATE.warning, "Select a QG Localization");
      return false;
    }
    if (!qgForm.qgDefectId) {
      this.toast.showToast(TOAST_STATE.warning, "Select a QG Defect");
      return false;
    }

    return true;
  }

  public onFileSelected(event: any) {
    this.file = event.target.files[0] ?? null;
    if (this.file) {
      this.fileName = this.file.name;
    }
  }

  public downloadFile() {
    this.fileService.download(this.rtmEntity.fileBucketKey, this.rtmEntity.fileName, this.rtmEntity.fileType);
  }

  public removeFile() {
    this.file = null;
    this.fileName = "";
  }
}
