import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { combineLatest, first } from "rxjs";
import {
  TOAST_STATE,
  ToastService,
} from "src/app/shared/services/toast.service";
import { Combo } from "src/app/shared/models/combo.model";
import { Market } from "src/app/shared/models/market.model";
import { ChangePointManagement } from "../models/change-point-management";
import { ComboService } from "src/app/shared/services/combo.service";
import { ChangePointManagementService } from "../services/change-point-management.service";
import { MarketSelectionService } from "src/app/shared/services/market-selection.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-change-point-management-form",
  templateUrl: "./change-point-management-form.component.html",
  styleUrls: ["./change-point-management-form.component.scss"],
})
export class ChangePointManagementFormComponent implements OnInit {
  public selectedMarket: Market | undefined;
  private selectedMarketOld: Market | undefined;

  public title: string = "";
  public btnCancel: string = "";

  public visibleId: string = "";
  public visibleUsername: string = "";

  public id: number = 0;
  public marketId: number = 0;

  public entity = {} as ChangePointManagement;

  segmentList: Array<Combo> = [];
  functionList: Array<Combo> = [];
  issueTypeList: Array<Combo> = [];
  detailsList: Array<Combo> = [];
  detailsListAux: Array<Combo> = [];
  taktOptionList: Array<Combo> = [];
  responsibleAreaList: Array<Combo> = [];
  functionListAux: Array<Combo> = [];
  ddlShift = ["1st shift", "2nd shift"];

  issueTypeName: string = "";
  issueTypeId: number | null = null;

  //#region Forms

  public formArea: FormGroup = this.fb.group({
    segment: ["", Validators.required],
    functionArea: ["", Validators.required],
    shift: [null],
  });

  public formItem: FormGroup = this.fb.group({
    issueTypeId: ["", Validators.required],
    detailId: [null],
    occurenceDate: ["", Validators.required],
    duration: [null],
    chassis: [
      "",
      [Validators.required, Validators.minLength(7), Validators.maxLength(7)],
    ],
    batch: [""],
    comments: ["", [Validators.required, Validators.minLength(1)]],
  });

  //#endregion

  constructor(
    private fb: FormBuilder,
    private service: ChangePointManagementService,
    private toast: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private combo: ComboService,
    private userService: UserService,
    private mkSelectionService: MarketSelectionService
  ) {}

  ngOnInit(): void {
    var url = "change-point-management/search";
    this.pageSettings();

    this.mkSelectionService.selectedMarket$
      .pipe(first())
      .subscribe((market) => {
        this.selectedMarket = market;
      });
    this.mkSelectionService.selectedMarket$.subscribe((market) => {
      if (this.selectedMarketOld && market != this.selectedMarket) {
        if (this.title == "Edit") {
          this.mkSelectionService.onChangeMarket(url);
        } else {
          this.selectedMarket = market;
          this.loadData();
        }
      } else {
        this.selectedMarket = market;
        this.selectedMarketOld = { ...this.selectedMarket };
      }
    });

    if (this.selectedMarket && this.selectedMarket.id > 0) {
      this.loadData();
    } else {
      this.toast.showToast(TOAST_STATE.information, "Select a market");
    }
  }

  private pageSettings() {
    const path = this.activatedRoute.routeConfig?.path;
    if (path?.includes("create")) {
      this.title = "New";
      this.btnCancel = "Cancel";
      this.marketId = parseInt(
        this.activatedRoute.snapshot.paramMap.get("marketId")?.toString() ?? "0"
      );
    } else {
      this.title = "Edit";
      this.btnCancel = "Back";
      this.visibleId = this.activatedRoute.snapshot.paramMap.get("id") ?? "0";
      this.id = parseInt(this.visibleId);
    }
  }

  private loadData(): void {
    const marketId = this.selectedMarket!.id;
    const setups = [
      "Segment",
      "Function",
      "Issue Type",
      "Detail",
      "Takt Option",
      "Responsible Area",
    ];
    this.clearCombos();

    const segments = this.combo.getSetupItemType(marketId, "CPM", setups[0]);
    const functions = this.combo.getSetupItemType(marketId, "CPM", setups[1]);
    const issueTypes = this.combo.getSetupItemType(marketId, "CPM", setups[2]);
    const details = this.combo.getSetupItemType(marketId, "CPM", setups[3]);
    const taktOptions = this.combo.getSetupItemType(marketId, "CPM", setups[4]);
    const areas = this.combo.getSetupItemType(marketId, "CPM", setups[5]);

    combineLatest([
      segments,
      functions,
      issueTypes,
      details,
      taktOptions,
      areas,
    ]).subscribe(
      ([segments, functions, issueTypes, details, taktOptions, areas]) => {
        this.segmentList = segments;
        this.functionList = functions;
        this.issueTypeList = issueTypes;
        this.detailsList = details;
        this.taktOptionList = taktOptions;
        this.responsibleAreaList = areas;

        if (this.id) {
          this.loadCpm(this.id);
        }
      }
    );
  }

  private clearCombos() {
    this.segmentList = [];
    this.functionList = [];
    this.issueTypeList = [];
    this.detailsList = [];
    this.taktOptionList = [];
    this.responsibleAreaList = [];
  }

  private loadCpm(id: number) {
    this.service.getById(id).subscribe({
      next: (data: ChangePointManagement) => {
        if (data) {
          this.setFormData(data);
        } else {
          this.toast.showToast(
            TOAST_STATE.error,
            "Can't load CPM data correctly"
          );
        }
      },
    });
  }

  private setFormData(data: ChangePointManagement) {
    this.functionListAux = this.functionList.filter(
      (d) => d.parentId === data.segmentId
    );

    this.formArea.patchValue({
      segment: data.segmentId,
      functionArea: data.functionId,
      shift: data.shift,
    });

    this.issueTypeName =
      this.issueTypeList.find((i) => i.id == data.issueTypeId)?.label ?? "";
    this.detailsListAux = this.detailsList.filter(
      (d) => d.parentId === data.issueTypeId
    );
    this.formItem.patchValue({
      issueTypeId: data.issueTypeId,
      detailId: data.detailId,
      occurenceDate: data.occurrenceDate,
      duration: data.duration,
      chassis: data.chassis,
      batch: data.batch,
      comments: data.comments,
    });
  }

  //#region

  public changeSegment(segmentId: any): void {
    const functionItem = this.functionList.find(
      (x) => x.parentId === segmentId
    );

    this.formArea.patchValue({
      functionId: "",
    });

    if (functionItem) {
      this.functionListAux = this.functionList.filter(
        (d) => d.parentId === segmentId
      );
    }
  }

  public onChangeIssueType(issueTypeId: any) {
    this.detailsListAux = this.detailsList.filter(
      (d) => d.parentId === issueTypeId
    );
  }

  //#endregion

  public save() {
    if (this.formArea.valid && this.formItem.valid) {
      const data = this.setEntity();

      this.service.save(data).subscribe({
        next: () => {
          this.toast.showToast(TOAST_STATE.success, "RTM Saved.");
          this.cancel();
        },
        error: (err) => {
          this.toast.showToast(TOAST_STATE.error, err.error);
        },
      });
    } else {
      this.toast.showToast(
        TOAST_STATE.warning,
        "Fill all required fields before saving"
      );
    }
  }

  private setEntity() {
    var user = "";
    this.userService.userName$.subscribe((userName) => {
      user = userName;
    });

    const entity: ChangePointManagement = {
      id: this.id,
      marketId: this.selectedMarket?.id!,
      username: user,
      //AREA
      segmentId: this.formArea.value.segment,
      functionId: this.formArea.value.functionArea,
      shift: this.formArea.value.shift,
      //ITEM
      issueTypeId: this.formItem.value.issueTypeId,
      detailId: this.formItem.value.detailId,
      occurrenceDate: this.formItem.value.occurenceDate,
      duration: this.formItem.value.duration,
      chassis: this.formItem.value.chassis,
      batch: this.formItem.value.batch,
      comments: this.formItem.value.comments,
    };
    return entity;
  }

  public cancel() {
    if (this.btnCancel == "Back") {
      this.router.navigateByUrl(
        "/change-point-management/search/" + this.marketId,
        {
          state: {
            marketId: this.marketId,
            filters: history.state.filters,
          },
        }
      );
    } else {
      this.router.navigate(["/change-point-management"]);
    }
  }
}
