<tds-breadcrumbs>
    <tds-breadcrumb>
        <a href="/real-time-management">Real Time Management</a>
    </tds-breadcrumb>
    <tds-breadcrumb current>
        <a href="#">Search</a>
    </tds-breadcrumb>
</tds-breadcrumbs>

<br>

<div class="sdds-card">

    <div class="sdds-card-body">

        <div [formGroup]="filtersForm">

            <div class="tds-row">
                <div class="tds-col-lg-6 tds-col-lg-6 tds-col-sm-6 tds-col-xs-12">
                    <mat-label>Segment</mat-label>
                    <mat-form-field>
                        <mat-select (selectionChange)="handleChange($event.value, 'segmentId')"
                            formControlName="segmentId">
                            <mat-option [value]="0">All</mat-option>
                            <mat-option *ngFor="let item of segmentList" [value]="item.id">{{item.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="tds-col-lg-6 tds-col-lg-6 tds-col-sm-6 tds-col-xs-12">
                    <mat-label>Function Area</mat-label>
                    <mat-form-field>
                        <mat-select formControlName="functionId">
                            <mat-option [value]="0">All</mat-option>
                            <mat-option *ngFor="let i of functionListAux" [value]="i.id">{{i.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="tds-row">
                <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-5 tds-col-xs-12">
                    <mat-label>Issue Type</mat-label>
                    <mat-form-field>
                        <mat-select (selectionChange)="handleChange($event.value, 'issueTypeId')"
                            formControlName="issueTypeId">
                            <mat-option [value]="0">All</mat-option>
                            <mat-option *ngFor="let item of issueTypeList" [value]="item.id">{{item.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-5 tds-col-xs-12">
                    <mat-label>Detail/Origin</mat-label>
                    <mat-form-field>
                        <mat-select formControlName="detailId">
                            <mat-option [value]="0">All</mat-option>
                            <mat-option *ngFor="let i of detailsListAux" [value]="i.id">{{i.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-2 tds-col-xs-12">
                    <mat-label>Status</mat-label>
                    <mat-form-field>
                        <mat-select formControlName="status">
                            <mat-option [value]="'null'">All</mat-option>
                            <mat-option [value]="'true'">Opened</mat-option>
                            <mat-option [value]="'false'">Closed</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="tds-row">
                <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-6 tds-col-xs-12">
                    <mat-label>Period From</mat-label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="periodFrom" formControlName="periodFrom">
                        <mat-datepicker-toggle matIconSuffix [for]="periodFrom"></mat-datepicker-toggle>
                        <mat-datepicker #periodFrom></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-6 tds-col-xs-12">
                    <mat-label>Period To</mat-label>
                    <mat-form-field>
                        <input matInput [matDatepicker]="periodTo" formControlName="periodTo">
                        <mat-datepicker-toggle matIconSuffix [for]="periodTo"></mat-datepicker-toggle>
                        <mat-datepicker #periodTo></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

        </div>
    </div>

    <div class="sdds-card-footer">
        <tds-button type="button" variant="primary" size="sm" text="Search" (click)="search()"></tds-button>
        <app-excel-download-button [service]="'RTM'" [method]="'generate'" [params]="filtersForm.value" [text]="'Generate'"></app-excel-download-button>
        <div *ngIf="isUserMaster" class="btn-master">
            <app-excel-download-button [service]="'RTM'" [method]="'master'" [params]="null" [text]="'Master'"></app-excel-download-button>
        </div>
    </div>
</div>

<div class="sdds-card" *ngIf="showTable">
    <div class="sdds-card-body">
        <div style="overflow-x: auto;">
            <table class="sdds-table sdds-table--divider sdds-table--no-min-width">
                <thead class="sdds-table__header">
                    <tr class="sdds-table__row">
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">ID</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Description</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Date</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Andon/Stop Time</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Function Area</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Chassis</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Type of Issue</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Status</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Actions</th>
                    </tr>
                </thead>
                <tbody class="sdds-table__body">
                    <tr class="sdds-table__row" *ngFor="let item of searchResult">
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.id}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.comments}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.occurrenceDate.substring(0,
                            item.occurrenceDate.indexOf('T'))}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.stopTime}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.functionDesc}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.chassis}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.issueTypeDesc}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.status ? "Opened" :
                            "Closed"}}
                        </td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 action-buttons">
                            <img src="assets\icons\edit.svg" title="Edit" (click)="edit(item.id)">
                            <img src="assets\icons\trash.svg" title="Delete"
                                (click)="modalService.openConfirmation(item.id, delete.bind(this))">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<app-confirmation-modal size="xs" modalId="deletion-modal" title="Delete Real Time Management"
    message="Are you sure you want to delete this RTM item?">
</app-confirmation-modal>