<h2 class="title-rtm">Real Time Management</h2>

<div class="button-container">

    <button class="sdds-btn sdds-btn-primary sdds-btn-fullbleed" (click)="register()">
        Register
    </button>
    <br>
    <button class="sdds-btn sdds-btn-primary sdds-btn-fullbleed" (click)="search()">
        Status/Update
    </button>
    <br>
    <button class="sdds-btn sdds-btn-primary sdds-btn-fullbleed" (click)="setup()">
        Setup
    </button>

</div>