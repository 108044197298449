import { Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent {

  @Input() size: string = 'md';
  @Input() modalId!: string;
  @Input() title!: string;
  @Input() action: string = 'Save';
  @Input() cancel: string = 'Cancel';

  @Output() save = new EventEmitter<any>();
  //@Output() cancel = new EventEmitter<void>();

}