import { Component, OnInit } from "@angular/core";
import { Combo } from "src/app/shared/models/combo.model";
import { ComboService } from "src/app/shared/services/combo.service";
import {
  TOAST_STATE,
  ToastService,
} from "src/app/shared/services/toast.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { CpmSearchResult } from "../models/cpm-search-result";
import { CpmSearchFilters } from "../models/cpm-search-filters";
import { ChangePointManagementService } from "../services/change-point-management.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MarketSelectionService } from "src/app/shared/services/market-selection.service";
import { first } from "rxjs";
import { Market } from "src/app/shared/models/market.model";

@Component({
  selector: "app-change-point-management-search",
  templateUrl: "./change-point-management-search.component.html",
  styleUrls: ["./change-point-management-search.component.scss"],
})
export class ChangePointManagementSearchComponent implements OnInit {
  private selectedMarket: Market | any;
  private selectedMarketOld: Market | any;

  public isUserMaster: boolean = true;
  public marketId: number | any;

  public showTable: boolean = true;
  public cpmList: CpmSearchResult[] = [];

  segmentList: Array<Combo> = [];
  functionList: Array<Combo> = [];
  issueTypeList: Array<Combo> = [];
  detailsList: Array<Combo> = [];
  detailsListAux: Array<Combo> = [];
  functionListAux: Array<Combo> = [];

  setupItens: Array<any> = [];
  issueTypeArray: Array<any> = [];

  issueTypeId: number | null = null;
  setupTypeId: number = 0;
  setupTypeName: string = "";
  issueTypeName: string = "";

  filtro: CpmSearchFilters = {
    marketId: "",
    segmentId: "",
    functionId: "",
    issueTypeId: "",
    detailId: "",
    periodFrom: "",
    periodTo: "",
  };

  public searchCpmForm: FormGroup = this.fb.group({
    marketId: [""],
    segmentId: [""],
    functionId: [""],
    issueTypeId: [""],
    detailId: [""],
    status: [""],
    periodFrom: [""],
    periodTo: [""],
  });

  constructor(
    private comboService: ComboService,
    public modalService: ModalService,
    private cpmService: ChangePointManagementService,
    private toast: ToastService,
    private fb: FormBuilder,
    private router: Router,
    private mkSelectionService: MarketSelectionService
  ) {}

  ngOnInit(): void {
    this.mkSelectionService.selectedMarket$
      .pipe(first())
      .subscribe((market) => {
        this.selectedMarket = market;
      });

    this.mkSelectionService.selectedMarket$.subscribe((market) => {
      this.selectedMarket = market;
      if (
        this.selectedMarketOld &&
        this.selectedMarketOld != this.selectedMarket
      ) {
        this.init();
      } else {
        this.selectedMarketOld = { ...this.selectedMarket };
      }
    });

    this.init();

    if (history.state.filters) {
      // set value for autoFill
      this.filtro.segmentId = history.state.filters.segmentId;
      this.filtro.functionId = history.state.filters.functionId;
      this.filtro.issueTypeId = history.state.filters.issueTypeId;
      this.filtro.detailId = history.state.filters.detailId;
      this.filtro.periodFrom = history.state.filters.periodFrom;
      this.filtro.periodTo = history.state.filters.periodTo;

      // set formFilterValue
      this.setFilters(this.filtro);
    }
  }

  private init() {
    this.marketId = this.selectedMarket.id;
    this.loadComboSegment();
    this.loadComboFunctionArea();
    this.loadComboMainType();
    this.loadComboDetails();
  }

  loadCombo(item: string, arrayToFill: Combo[]): void {
    this.comboService.getSetupItemType(this.marketId, "CPM", item).subscribe({
      next: (result) => {
        if (result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            arrayToFill.push({
              label: result[i].label,
              id: result[i].id,
              parentId: result[i].parentId,
            });
          }
        }
      },
      error: (e) => {
        this.toast.showToast(TOAST_STATE.error, `Could't load ${item} data`);
      },
    });
  }

  loadComboSegment(): void {
    this.segmentList = [];
    this.loadCombo("Segment", this.segmentList);
  }

  loadComboFunctionArea(): void {
    this.functionList = [];
    this.loadCombo("Function", this.functionList);
  }

  loadComboMainType(): void {
    this.issueTypeList = [];
    this.loadCombo("Issue Type", this.issueTypeList);
  }

  loadComboDetails(): void {
    this.detailsList = [];
    this.loadCombo("Detail", this.detailsList);
  }

  setFilters(filters: any): void {
    this.searchCpmForm.patchValue({
      marketId: this.marketId,
      segmentId: filters.segmentId,
      functionId: filters.functionId,
      issueTypeId: filters.issueTypeId,
      detailId: filters.detailId,
      periodFrom: filters.periodFrom,
      periodTo: filters.periodTo,
    });

    this.search();
  }

  getDropdownLabel(id: any, ddlName: string) {
    var label;

    switch (ddlName) {
      case "segment":
        label = this.segmentList.find((x) => x.id == id)?.label;
        if (label) this.onChangeFunctionBySegment(id);
        break;
      case "function":
        label = this.functionList.find((x) => x.id == id)?.label;
        break;
      case "item":
        label = this.issueTypeList.find((x) => x.id == id)?.label;
        if (label) this.onChangeIssueType(id);
        break;
      case "details":
        label = this.detailsList.find((x) => x.id == id)?.label;
        break;
      default:
        break;
    }

    return label ? label : "All";
  }

  handleChange(event: any, atr: string) {
    const selectedValue = event.target.value;
    this.searchCpmForm.get(atr)?.setValue(event.detail.label);

    switch (atr) {
      case "segmentId":
        this.filtro.segmentId = selectedValue;
        break;
      case "functionId":
        this.filtro.functionId = selectedValue;
        this.onChangeFunctionBySegment(this.filtro.functionId);
        break;
      case "issueTypeId":
        this.filtro.issueTypeId = selectedValue;
        this.onChangeIssueType(this.filtro.issueTypeId);
        break;
      case "detailId":
        this.filtro.detailId = selectedValue;
        break;
      default:
        break;
    }
  }

  onChangeIssueType(issueTypeId: any) {
    this.detailsListAux = this.detailsList.filter(
      (d) => d.parentId == issueTypeId
    );
  }

  onChangeFunctionBySegment(functionId: any) {
    this.functionListAux = this.functionList.filter(
      (d) => d.parentId == functionId
    );
  }

  public search() {
    const filters: CpmSearchFilters = {
      marketId: this.marketId,
      segmentId: this.filtro.segmentId == "All" ? "" : this.filtro.segmentId,
      functionId: this.filtro.functionId == "All" ? "" : this.filtro.functionId,
      issueTypeId:
        this.filtro.issueTypeId == "All" ? "" : this.filtro.issueTypeId,
      detailId: this.filtro.detailId == "All" ? "" : this.filtro.detailId,
      periodFrom: this.searchCpmForm.value.periodFrom,
      periodTo: this.searchCpmForm.value.periodTo,
    };

    this.cpmService.getFiltered(filters).subscribe({
      next: (result) => {
        this.cpmList = result;
        this.showTable = true;
      },
      error: (err) => {
        this.toast.showToast(TOAST_STATE.error, err);
        this.showTable = false;
      },
    });
  }

  public edit(id: number) {
    if (id !== null) {
      this.router.navigateByUrl(`/change-point-management/edit`, {
        state: {
          cpmId: id,
          marketId: this.marketId,
          filters: this.filtro,
        },
      });
    }
  }

  public delete(id: number): void {
    if (id) {
      this.cpmService.delete(id).subscribe({
        next: (result) => {
          if (result) {
            this.toast.showToast(TOAST_STATE.success, "CPM deleted");
            this.cpmList = this.cpmList.filter((item) => item.id !== id);
          } else {
            this.toast.showToast(TOAST_STATE.error, "Can't delete now");
          }
        },
        error: (e) => {
          this.toast.showToast(TOAST_STATE.error, e.error);
        },
      });
    }
  }
}
