import { EventEmitter, OnDestroy, Output } from "@angular/core";
import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { Subject } from "rxjs";
import packageJson from "../../package.json";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  @Output() eventFromHeader = new EventEmitter<any>();

  isIframe = false;
  mobileMenuOpen = false;

  private readonly _destroying$ = new Subject<void>();
  public appVersion: string = "";
  constructor(private authService: MsalService) {}

  ngOnInit(): void {
    this.appVersion = packageJson.version;
    setTimeout(() => {
      this.checkAccount();
    }, 10);
  }

  checkAccount() {
    const account = this.authService.instance.getAllAccounts()[0];

    if (!account) {
      this.authService.instance.loginRedirect();
    } else {
      var roles: string[] = [];
      var markets: string[] = [];

      account.idTokenClaims!.roles?.forEach((mkt) => {
        if (mkt.includes("MARKET")) {
          markets.push(mkt);
        } else {
          roles.push(mkt);
        }
      });

      let user = {
        name: account.name,
        email: account.username,
        roles: roles,
        markets: markets,
      };
      sessionStorage.setItem("rtm-cpm-user", JSON.stringify(user));
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  handleEvent($event: any) {
    switch ($event.type) {
      case "showMobileMenu":
        this.mobileMenuOpen = $event.value;
        break;
    }
  }
}
