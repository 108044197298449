import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private userNameSubject = new BehaviorSubject<string>("");
  userName$ = this.userNameSubject.asObservable();

  private userEmailSubject = new BehaviorSubject<string>("");
  userEmail$ = this.userEmailSubject.asObservable();

  constructor() {}

  setUserInfo(name: string, email: string) {
    this.userNameSubject.next(name);
    this.userEmailSubject.next(email);
  }
}
