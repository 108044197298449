<tds-breadcrumbs>
    <tds-breadcrumb>
        <a href="/real-time-management">Real Time Management</a>
    </tds-breadcrumb>
    <tds-breadcrumb current>
        <a href="#">Setup</a>
    </tds-breadcrumb>
</tds-breadcrumbs>

<br>

<div id="cardMain" class="sdds-card">

    <div class="sdds-card-body">
        <form [formGroup]="formSearch">

            <div class="tds-row">
                <div class="tds-col-lg-6 tds-col-lg-6 tds-col-sm-6 tds-col-xs-12">
                    <mat-label>Setup item:</mat-label>
                    <mat-form-field>
                        <mat-select (selectionChange)="onChangeSetup($event)" formControlName="setup">
                            <mat-option value="">All</mat-option>
                            <mat-option *ngFor="let item of comboSetup" [value]="item.id">{{item.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

    <div class="sdds-card-footer">
        <button class="sdds-btn sdds-btn-sm sdds-btn-primary" (click)="search()">Search</button>&nbsp;
        <button class="sdds-btn sdds-btn-sm sdds-btn-primary" (click)="openModalSetup()" id="btnNew">New</button>
    </div>
</div>

<div id="cardTable" class="sdds-card" *ngIf="showTable">

    <div class="sdds-card-header">
        <h4 class="sdds-card-headline">Search Result</h4>
    </div>

    <div class="sdds-divider-light-border-top"></div>

    <div class="sdds-card-body">

        <div style="overflow-x: auto;">
            <table class="sdds-table sdds-table--divider" style="width: 100%;">
                <thead class="sdds-table__header">
                    <tr class="sdds-table__row">
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Description</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Issue Type</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Action</th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="sdds-table__row" *ngFor="let item of resultSetup">
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.description}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.parentDesc}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 action-buttons">
                            <img src="assets\icons\trash.svg"
                                (click)="modalService.openConfirmation(item.id, delete.bind(this))" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</div>

<!-- CREATE MODAL -->
<app-form-modal id="forModal" size="md" modalId="form-modal" title="{{modalTitle}}" (save)=" saveSetupRtm()">
    <form [formGroup]="formSetup">

        <div class="sdds-row">

            <mat-label class="mat-label-outside">Setup Item:</mat-label>
            <mat-form-field class="sdds-on-white-bg" appearance="fill" style="width:100%">
                <mat-select formControlName="setupItemId" [errorState]="eStateSetupItem"
                    (selectionChange)="onChangeModalSetup($event)" class="sdds-dropdown">
                    <mat-option value="">Choose...</mat-option>
                    <mat-option *ngFor="let item of comboModalSetup" class="option-style" [value]="item.id">
                        {{item.label}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="eStateSetupItem">{{ eMsgSetupItem }}</mat-error>
            </mat-form-field>

        </div>

        <div class="sdds-row" *ngIf="hasParentId">
            <mat-label class="mat-label-outside">{{ labelParentId }}</mat-label>
            <mat-form-field appearance="fill" style="width:100%">
                <mat-select formControlName="parentId" (selectionChange)="onChangeModalAux($event)"
                    placeholder="Choose...">
                    <ng-container>
                        <mat-option *ngFor="let item of comboModalAux" [value]="item.id">
                            {{item.label}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="sdds-row">
            <mat-label class="mat-label-outside">Description</mat-label>
            <mat-form-field appearance="fill" style="width:100%">
                <input matInput formControlName="description" type="text">
                <mat-error *ngIf="eStateDesc">{{ eMsgDescShow }}</mat-error>
            </mat-form-field>

        </div>

    </form>

</app-form-modal>

<app-confirmation-modal size="xs" modalId="deletion-modal" title="Delete RTM Setup"
    message="Are you sure you want to delete this setup?">
</app-confirmation-modal>